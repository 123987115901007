import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import SlidesLayout from 'layouts/Slides';
import ParticipantLayout from 'layouts/Participant.js';
import ParticipantFeedbackLayout from 'layouts/ParticipantFeedback.js';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.js';
import ZoomAuth from 'views/ZoomAuth/ZoomAuth';
import Notifier from './components/Notifier';
import ErrorFallback from 'components/ErrorFallback';
import VolunteerSignup from 'layouts/VolunteerSignup.js';

const App = () => (
  <Sentry.ErrorBoundary fallback={() => <ErrorFallback />} showDialog>
    <Notifier />
    <Router>
      <Switch>
        <Route path="/signup" component={AuthLayout} />
        <Route path="/zoom-signup" component={ZoomAuth} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/feedback/:sessionId" component={ParticipantFeedbackLayout} />
        <Route path="/slides/:slidesSessionId" component={SlidesLayout} />
        <Route path="/session/:sessionId" component={ParticipantLayout} />
        <Route path="/volunteer-signup/:projectId" component={VolunteerSignup} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin" />
      </Switch>
    </Router>
  </Sentry.ErrorBoundary>
);

export default App;
