import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { SENIORITY_SELECT_OPTIONS, JOB_ROLE_SELECT_OPTIONS } from 'constants/staffing';
import { TIME_ZONES } from 'constants/timeZones';

// library components
import { Formik, Form, FieldArray, Field } from 'formik';
import { Button, Grid, InputLabel, IconButton, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import ClearIcon from '@material-ui/icons/Clear';

//validation library
import * as Yup from 'yup';

// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
import TextArea from 'components/CustomFormComponents/TextArea';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';

//actions & selectors
import { createContractorUser, updateContractorUser } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import {
  selectContractor,
  contractorFiltersDerivedSelectOptions,
} from 'redux/selectors/staffing/getContractor';
import { STAFFING_TYPE_SELECT_OPTIONS } from 'constants/staffing';
import { useFirestore } from 'react-redux-firebase';
import AutoSuggestTextInput from 'components/CustomFormComponents/AutoSuggestTextInput';
import { map } from 'lodash';
import CustomCheckbox from 'components/CustomFormComponents/CustomCheckbox';
import MaterialDate from 'components/CustomFormComponents/MaterialDate';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '200px',
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    textAlign: 'right',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 6,
  },
  removeButton: {
    marginLeft: 5,
    borderRadius: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  addButton: {
    width: 80,
    marginTop: 5,
    background: '#DCE5EF',
    borderRadius: 5,
    color: '#093B75',
    fontSize: 14,
    '&:hover': {
      background: '#DCE5EF',
      opacity: 0.9,
    },
  },
  addRoleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 25,
    background: '#DCE5EF',
    borderRadius: 5,
    color: '#093B75',
    fontSize: 14,
    '&:hover': {
      background: '#DCE5EF',
      opacity: 0.9,
    },
  },
  removeRoleButton: {
    fontSize: 12,
  },
  cancelButton: {
    width: 105,
    height: 40,
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  helperText: {
    fontSize: 12,
    display: 'inline',
    color: theme.palette.text.hint,
    fontStyle: 'italic',
  },
}));

const FieldText = React.memo(({ name }) => (
  <Field
    name={`${name}`}
    component={({ field }) => (
      <TextInput name={name} type="text" className="flex-grow-1" required />
    )}
  />
));

const AddContractorForm = ({ closeDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const db = useFirestore();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displaySecondaryRole, setDisplaySecondaryRole] = useState(false);

  const { contractorToUpdateId } = useStaffingSelector();
  const isEditing = !!contractorToUpdateId;
  const getContractor = selectContractor();

  //Get the Contractor Info from the Store
  const contractor = useSelector(state => getContractor(state, contractorToUpdateId));

  const getFilters = contractorFiltersDerivedSelectOptions();
  const { cities, states, countries, tags, languages } = useSelector(state => getFilters(state));

  useEffect(() => {
    setDisplaySecondaryRole(contractor?.secondaryRoleType);
  }, [contractor]);

  const formInitialValues = {
    type: contractor?.type ?? '',
    secondaryRoleType: contractor?.secondaryRoleType ?? '',
    staffType: contractor?.staffType ?? '',
    email: contractor?.email ?? '',
    vcEmail: contractor?.vcEmail ?? '',
    circleUserId: contractor?.circleUserId ?? '',
    circleUserEmail: contractor?.circleUserEmail ?? '',
    displayName: contractor?.displayName ?? '',
    otherEmails: contractor?.otherEmails ?? [],
    phones: contractor?.phones ?? [],
    seniority: contractor?.seniority ?? '',
    hourlyRate: contractor?.hourlyRate ?? '',
    address: contractor?.address ?? '',
    // This simulates uncaught exception....
    city: contractor?.city ?? '',
    state: contractor?.state ?? contractor?.country ?? '',
    postalCode: contractor?.postalCode ?? '',
    country: contractor?.country ?? '',
    timeZone: contractor?.timeZone ?? '',
    emergencyContactName: contractor?.emergencyContactName ?? '',
    emergencyContactPhone: contractor?.emergencyContactPhone ?? '',
    emergencyContactEmail: contractor?.emergencyContactEmail ?? '',
    notes: contractor?.notes ?? '',
    flagComments: contractor?.flagComments ?? '',
    tags: contractor?.tags ?? [],
    languages: contractor?.languages ?? [],
    wpFacilitatorId: contractor?.wpFacilitatorId ?? '',
    hasVCZoomLicense: contractor?.hasVCZoomLicense ?? contractor?.staffType === 'Employee',
    birthday: contractor?.birthday ?? '',
    flightPreferences: contractor?.flightPreferences ?? '',
  };

  const formSchema = Yup.object().shape({
    type: Yup.string().required('Required'),
    email: Yup.string().email('Must be a valid email address').required('Required'),
    vcEmail: Yup.string()
      .email('Must be a valid email address')
      .test('vc-email-domain', 'VC Email must be a voltagecontrol.com email.', function (value) {
        return !value || (value && value.endsWith('@voltagecontrol.com'));
      }),
    circleUserId: Yup.string(),
    otherEmails: Yup.array().of(Yup.string().email('Must be a valid email address')),
    phones: Yup.array().of(Yup.string()),
    secondaryRoleType: displaySecondaryRole ? Yup.string().required('Required') : Yup.string(),
    staffType: Yup.string().required('Required'),
    displayName: Yup.string().required('Required'),
    seniority: Yup.string().required('Required'),
    hourlyRate: Yup.string().required('Required'),
    address: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string(),
    postalCode: Yup.string(),
    country: Yup.string().required('Required'),
    emergencyContactName: Yup.string(),
    emergencyContactPhone: Yup.string(),
    emergencyContactEmail: Yup.string().email('Must be a valid email address'),
    timeZone: Yup.string(),
    notes: Yup.string(),
    flagComments: Yup.string(),
    wpFacilitatorId: Yup.string(),
    hasVCZoomLicense: Yup.boolean(),
    birthday: Yup.date(),
    flightPreferences: Yup.string(),
  });

  const handleFormSubmit = async (values, { setErrors }) => {
    setIsSubmitting(true);

    if (isEditing && formInitialValues.email !== values.email) {
      const emailIsExistingUser = Boolean(
        (await db.collection('users').where('email', '==', values.email).get()).docs.length,
      );

      if (emailIsExistingUser) {
        setErrors({ email: 'Email already in use for another user' });
        setIsSubmitting(false);
        return;
      }
    }

    if (!isEditing) {
      const emailIsExistingContractor = Boolean(
        (await db.collection('contractors').where('email', '==', values.email).get()).docs.length,
      );

      if (emailIsExistingContractor) {
        setErrors({ email: 'Email already in use for another contractor' });
        setIsSubmitting(false);
        return;
      }
    }

    const successCallback = () => {
      setIsSubmitting(false);
      closeDialog();
    };

    if (!displaySecondaryRole) {
      delete values.secondaryRoleType;
    }

    const tags = values.tags ?? [];

    if (isEditing) {
      dispatch(
        updateContractorUser(contractorToUpdateId, { ...values, tags }, true, successCallback),
      );
    } else {
      dispatch(createContractorUser({ ...values, tags }, true, successCallback));
    }
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={handleFormSubmit}
    >
      {({ submitForm, resetForm, values, errors, submitCount, touched }) => (
        <Form style={{ paddingBottom: 20 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <TextInput
                name="displayName"
                type="text"
                label="Staff Name"
                className="mb-4"
                required
              />

              <TextInput
                name="email"
                type="email"
                label="Primary Email"
                className="mb-4"
                required
              />

              <TextInput name="vcEmail" type="email" label="VC Email" className="mb-4" />

              <TextInput name="circleUserId" type="text" label="Circle User ID" className="mb-4" />

              <TextInput name="circleUserEmail" type="email" label="Circle User Email" className="mb-4" />

              <TextInput name="wpFacilitatorId" label="WP Facilitator ID" className="mb-4" />

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <TextInput name="address" type="text" label="Address" className="mb-4" />
                </Grid>
                <Grid item xs={6}>
                  <AutoSuggestTextInput
                    name="city"
                    label="City"
                    required
                    options={map(cities, 'name')}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <AutoSuggestTextInput name="state" label="State" options={map(states, 'name')} />
                </Grid>
                <Grid item xs={6}>
                  <TextInput name="postalCode" type="text" label="Postal Code" className="mb-4" />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <AutoSuggestTextInput
                    name="country"
                    label="Country"
                    required
                    options={map(countries, 'name')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MaterialDate
                    name="birthday"
                    label="Birthday"
                    className="mb-0"
                    additionalStyles={{
                      container: classes.selectContainer,
                      label: classes.inputLabel,
                    }}
                    allowKeyboardInput={true}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <InputLabel variant="standard">Secondary Emails</InputLabel>
                  <FieldArray
                    name="otherEmails"
                    render={arrayHelpers => (
                      <div>
                        {values.otherEmails.map((_, index) => (
                          <div key={index} className={classes.row}>
                            <FieldText name={`otherEmails.${index}`} />
                            <IconButton
                              size="small"
                              className={classes.removeButton}
                              onClick={() => arrayHelpers.remove(index)} // remove an email from the list
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        ))}

                        <Button
                          className={cx(classes.addButton, {
                            'mt-1': !values.otherEmails.length,
                          })}
                          onClick={() => arrayHelpers.insert(values.otherEmails.length, '')}
                        >
                          Add
                          <AddCircleIcon />
                        </Button>
                      </div>
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel variant="standard">Phone Numbers</InputLabel>

                  <FieldArray
                    name="phones"
                    render={arrayHelpers => (
                      <div>
                        {values.phones.map((_, index) => (
                          <div key={index} className={classes.row}>
                            <FieldText name={`phones.${index}`} />
                            <IconButton
                              size="small"
                              className={classes.removeButton}
                              onClick={() => arrayHelpers.remove(index)} // remove an Phone from the list
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        ))}

                        <Button
                          className={cx(classes.addButton, {
                            'mt-1': !values.phones.length,
                          })}
                          onClick={() => arrayHelpers.insert(values.phones.length, '')} // insert an empty string at the end
                        >
                          Add
                          <AddCircleIcon />
                        </Button>
                      </div>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <InputLabel variant="standard">Tags</InputLabel>

                  <FieldArray
                    name="tags"
                    render={arrayHelpers => (
                      <div>
                        {values.tags.map((_, index) => (
                          <div key={index} className={classes.row}>
                            <AutoSuggestTextInput
                              name={`tags.${index}`}
                              required
                              options={map(tags, 'name')}
                              customStyles={{ container: 'w-100' }}
                            />
                            <IconButton
                              size="small"
                              className={classes.removeButton}
                              onClick={() => arrayHelpers.remove(index)} // remove an Tag from the list
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        ))}

                        <Button
                          className={cx(classes.addButton, {
                            'mt-1': !values.tags.length,
                          })}
                          onClick={() => arrayHelpers.insert(values.tags.length, '')} // insert an empty string at the end
                        >
                          Add
                          <AddCircleIcon />
                        </Button>
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel variant="standard">Languages</InputLabel>

                  <FieldArray
                    name="languages"
                    render={arrayHelpers => (
                      <div>
                        {values.languages.map((_, index) => (
                          <div key={index} className={classes.row}>
                            <AutoSuggestTextInput
                              name={`languages.${index}`}
                              required
                              options={map(languages, 'name')}
                              customStyles={{ container: 'w-100' }}
                            />
                            <IconButton
                              size="small"
                              className={classes.removeButton}
                              onClick={() => arrayHelpers.remove(index)} // remove an Tag from the list
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        ))}

                        <Button
                          className={cx(classes.addButton, {
                            'mt-1': !values.tags.length,
                          })}
                          onClick={() => arrayHelpers.insert(values.tags.length, '')} // insert an empty string at the end
                        >
                          Add
                          <AddCircleIcon />
                        </Button>
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Divider orientation="vertical" flexItem style={{ marginRight: '-1px' }} />

            <Grid item xs={12} md={6}>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Primary Role"
                    className="mb-4"
                    name="type"
                    type="select"
                    placeholder=""
                    selectOptions={JOB_ROLE_SELECT_OPTIONS}
                  />
                  {displaySecondaryRole && (
                    <CustomSelect
                      label="Secondary Role"
                      className="mb-4"
                      name="secondaryRoleType"
                      type="select"
                      placeholder=""
                      selectOptions={JOB_ROLE_SELECT_OPTIONS}
                    />
                  )}

                  <Button
                    className={cx(
                      classes.addRoleButton,
                      displaySecondaryRole && classes.removeRoleButton,
                      {
                        'mt-1': !values.otherEmails.length,
                      },
                    )}
                    onClick={() => {
                      setDisplaySecondaryRole(prev => !prev);
                    }}
                  >
                    {displaySecondaryRole ? 'Remove' : 'Add'} secondary role
                    {displaySecondaryRole ? <RemoveCircleIcon /> : <AddCircleIcon />}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Staff Type"
                    className="mb-4"
                    name="staffType"
                    type="select"
                    placeholder=""
                    selectOptions={STAFFING_TYPE_SELECT_OPTIONS}
                  />

                  <div>
                    <CustomCheckbox name="hasVCZoomLicense" label="VC Zoom License" />
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <CustomSelect
                    label="Seniority"
                    className="mb-4"
                    name="seniority"
                    type="select"
                    placeholder=""
                    required
                    selectOptions={SENIORITY_SELECT_OPTIONS}
                  ></CustomSelect>
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    // customStyles={{ label: 'mb-1' }}
                    name="hourlyRate"
                    type="text"
                    label="Hourly Rate"
                    className="mb-4"
                    required
                  />
                </Grid>
              </Grid>

              <div className="mb-4">
                <AutoSuggestTextInput name="timeZone" label="Time Zone" options={TIME_ZONES} />
              </div>

              <TextArea
                name="notes"
                type="text"
                label="Notes"
                mutliline
                fullWidth
                rows={3}
                className="mb-4"
              />

              <TextArea
                name="flagComments"
                type="text"
                label="Flag Comments"
                mutliline
                fullWidth
                rows={3}
                className="mb-4"
              />

              <TextArea
                name="flightPreferences"
                type="text"
                label="Flight Preferences"
                mutliline
                fullWidth
                rows={3}
                className="mb-4"
              />

              <TextInput
                name="emergencyContactName"
                type="text"
                label="Emergency Contact Name"
                className="mb-4"
              />
              <TextInput
                name="emergencyContactPhone"
                type="text"
                label="Emergency Contact Phone"
                className="mb-4"
              />
              <TextInput
                name="emergencyContactEmail"
                type="email"
                label="Emergency Contact Email"
                className="mb-4"
              />
            </Grid>
          </Grid>

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                SAVE
                {isSubmitting && <ButtonSpinner />}
              </Button>
              {submitCount > 0 &&
                Object.keys(errors).length > 0 &&
                Object.keys(touched).length > 0 && (
                  <Typography color="error" className={classes.errorMessage}>
                    See errors above.
                  </Typography>
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddContractorForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AddContractorForm;
