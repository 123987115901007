import React, { useState } from 'react';

import { Form, Formik } from 'formik';
//validation library
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useSelector, useDispatch } from 'react-redux';
import { selectProject, selectVolunteerRole } from 'redux/selectors/staffing/getProject';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { addVolunteerRoleToProject } from 'redux/actions/staffingActions';

import { generateRandomId } from 'utils/generateRandomId';

import TextInput from 'components/CustomFormComponents/TextInput';
import ButtonSpinner from 'components/ButtonSpinner';
import TextArea from 'components/CustomFormComponents/TextArea';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
}));

const AddVolunteerRoleToProjectForm = props => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { closeDialog } = props;
  const classes = useStyles();
  const { dealProjectToUpdateId, volunteerRoleToUpdateId } = useStaffingSelector();
  const dispatch = useDispatch();
  const getProject = selectProject();
  const getVolunteerRole = selectVolunteerRole();

  const project = useSelector(state => getProject(state, dealProjectToUpdateId));

  const volunteerRole = useSelector(state =>
    getVolunteerRole(state, project?.id, volunteerRoleToUpdateId),
  );

  const formInitialValues = {
    title: volunteerRole ? volunteerRole.title : '',
    description: volunteerRole ? volunteerRole.description : '',
    seats: volunteerRole ? volunteerRole.seats : 1,
    displayOrder: volunteerRole ? volunteerRole.displayOrder : undefined,
  };

  const formSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    seats: Yup.number().required('Required'),
    displayOrder: Yup.number().required('Required'),
  });

  const handleFormSubmit = values => {
    setIsSubmitting(true);

    const volunteerRoleId = volunteerRoleToUpdateId || generateRandomId();

    dispatch(
      addVolunteerRoleToProject(dealProjectToUpdateId, volunteerRoleId, { ...values }, () => {
        setIsSubmitting(false);
        closeDialog();
      }),
    );
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={async values => {
        handleFormSubmit(values);
      }}
    >
      {({ submitForm, resetForm, errors, touched, submitCount }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput name="title" type="string" label="Title" className="mb-4" required />

          <TextArea
            name="description"
            type="text"
            label="Description (include services & deliverables)"
            multiline
            fullWidth
            required
            rows={4}
            className="mb-4"
          />

          <div style={{ gap: '2rem' }} className="d-flex flex-row justify-content-between">
            <TextInput
              name="seats"
              type="number"
              label="Number of Seats"
              className="mb-4 flex-1"
              required
            />

            <TextInput
              name="displayOrder"
              type="number"
              label="Display Order"
              className="mb-4 flex-1"
              required
            />
          </div>
          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                SAVE
                {isSubmitting && <ButtonSpinner />}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddVolunteerRoleToProjectForm;
