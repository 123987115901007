import { InternalApiClient } from "lib/InternalApiClient";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useInternalApiClient = () => {
  const token = useSelector((state) => state.auth?.token?.token);

  return useMemo(() => {
    return new InternalApiClient({ token });
  }, [token]);
};
