import { ApiResponseError, AxiosRetryClient } from '@reggieofarrell/axios-retry-client';

export class InternalApiClient extends AxiosRetryClient {
  constructor({ token }) {
    // if (!token) {
    //   throw new Error('token is required');
    // }

    let axiosConfig = {};

    if (token) {
      axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    super({
      baseURL: process.env.REACT_APP_INTERNAL_API_URL,
      axiosConfig,
    });
  }

  volunteer = {
    getProjectData: async projectId => {
      return (await this.get(`/projects/${projectId}/volunteer-page`)).data;
    },
    submitVolunteerSignup: async (projectId, data) => {
      return (await this.post(`/projects/${projectId}/volunteer`, data)).data;
    },
  };

  circle = {
    updateCircleUserForContractor: async contractorId => {
      return (
        await this.post(`/circle/update-circle-user-for-contractor`, {
          id: contractorId,
        })
      ).data;
    },
  };

  errorHandler(error, reqType, url) {
    if (error.response?.data) {
      const responseData = error.response.data;
      throw new ApiResponseError(
        `[${this.name}] ${reqType} ${url} : [${error.response?.status}] ${responseData.message}`,
        error.response.status,
        error.response.data,
      );
    } else {
      // Call the parent class's handleResponseNotReceivedOrOtherError for other types of errors
      this.handleResponseNotReceivedOrOtherError(error, reqType, url);
    }
  }
}
