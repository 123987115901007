import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { map, filter, find, some, values, size, compact, orderBy } from 'lodash';

// library components
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//validation library
import * as Yup from 'yup';

// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';

//actions & selectors
import { addContractorToJob } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import { selectProject, selectProjects } from 'redux/selectors/staffing/getProject';
import { selectDeals } from 'redux/selectors/staffing/getDeals';
import { selectCompanies } from 'redux/selectors/staffing/getDeals';

import CustomSearchableSelect from 'components/CustomFormComponents/CustomSearchableSelect';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  selectContainer: {
    paddingBottom: theme.spacing(4), // Adds 32px of padding to the bottom
  },
}));

const AddJobToContractorForm = ({ closeDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');

  const { contractorToUpdateId } = useStaffingSelector();

  const getAllProject = selectProjects();
  const { projects } = useSelector(state => getAllProject(state));

  const getAllCompanies = selectCompanies();
  const { companies } = useSelector(state => getAllCompanies(state));

  const getAllDeals = selectDeals();
  const { deals } = useSelector(state => getAllDeals(state));

  const getProject = selectProject();
  const selectedProject = useSelector(state => getProject(state, selectedProjectId));

  const formInitialValues = {
    project: selectedProjectId,
    job: '',
  };

  const formSchema = Yup.object().shape({
    project: Yup.string().required('Required'),
    job: Yup.string().required('Required'),
  });

  const availableJobFilter = job => {
    const assignees = values(job.assignees);
    const contractorAssigned = some(
      assignees,
      assignee => assignee.contractorId === contractorToUpdateId,
    );
    const signedCount = filter(assignees, assignee => assignee.status === 'signed').length;
    return !contractorAssigned && signedCount < job.seats;
  };

  /**
   * Return Active Projects
   */
  const getProjectSelectOptions = () => {
    const activeProjects = filter(compact(projects), project => {
      if (!project.isActive) {
        return false;
      }
      if (!project.hasJobs) {
        return false;
      }

      const availableJobs = filter(project.jobs, availableJobFilter);
      return availableJobs.length > 0;
    });

    return orderBy(
      map(activeProjects, project => {
        const deal = find(deals, { id: project.dealId });
        return {
          name: `${project.eventName} - ${deal.dealName} - ${deal.companyName}`,
          value: project.id,
        };
      }),
      option => option.name.toLowerCase(),
    );
  };

  /**
   * Get Jobs in Project
   */
  const getJobSelectOptions = () => {
    if (selectedProject) {
      const availableJobs = filter(selectedProject.jobs, availableJobFilter);

      return map(availableJobs, job => ({
        name: `${job.jobName} - ${job.jobRole}`,
        value: job.jobId,
      }));
    }

    return [];
  };

  const handleFormSubmit = values => {
    setIsSubmitting(true);

    const successCallback = () => {
      setIsSubmitting(false);
      closeDialog();
    };

    dispatch(addContractorToJob(values.project, values.job, contractorToUpdateId, successCallback));
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={async values => {
        handleFormSubmit(values);
      }}
    >
      {({ submitForm, resetForm, setFieldValue }) => (
        <Form style={{ paddingBottom: 20 }}>
          <div className={classes.selectContainer}>
            <CustomSearchableSelect
              label="Project"
              name="project"
              placeholder=""
              disableClearable
              afterChange={value => {
                setSelectedProjectId(value); //set the Project Id in state to derive the Jobs for the Selected Project
              }}
              valueOverride={{ name: selectedProject?.eventName, value: selectedProject?.id }}
              options={getProjectSelectOptions()}
              noOptionsText={'No projects with unassigned jobs'}
              // customStyles={{
              //   container: classes.selectContainer,
              //   label: classes.inputLabel,
              // }}
            />
          </div>

          {selectedProjectId && (
            <CustomSelect
              label="Job"
              className="mb-4"
              name="job"
              type="select"
              placeholder=""
              required
              selectOptions={getJobSelectOptions()}
            />
          )}

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
              ADD
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddJobToContractorForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AddJobToContractorForm;
