import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonSpinner = ({ size, className }) => {
  const spinnerSize = {
    default: 24,
    small: 14,
  };

  const styles = {
    default: {
      marginLeft: 12,
    },
    small: {
      marginLeft: 8,
    },
  };

  return (
    <div className="d-flex align-items-center justify-content-center" style={styles[size]}>
      <CircularProgress color="inherit" size={spinnerSize[size]} className={className} />
    </div>
  );
};

ButtonSpinner.defaultProps = {
  size: 'default',
  className: '',
};

ButtonSpinner.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonSpinner;
