import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button, makeStyles } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { updateVolunteer } from 'redux/actions/staffingActions';
import { getCurrentSidebarMeetingForUser } from 'redux/selectors/sidebarMeetingsSelectors';
import { generateRandomId } from 'utils/generateRandomId';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '200px', // Adjust this width as needed
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
}));

/**
 * Form used by facilitators to update the sessio title/topic
 */
const AddVolunteerToVolunteerRoleForm = ({ closeDialog, projectId, role, volunteer }) => {
  console.log('AddVolunteerToVolunteerRoleForm : props', {
    closeDialog,
    projectId,
    role,
    volunteer,
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inititalValues = {
    name: volunteer ? volunteer.name : '',
    email: volunteer ? volunteer.email : '',
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  return (
    <Formik
      initialValues={inititalValues}
      validationSchema={formSchema}
      onSubmit={async values => {
        setIsSubmitting(true);

        if (!volunteer) {
          values.roleId = role.id;
          values.id = generateRandomId();

          dispatch(
            updateVolunteer(projectId, values.id, values, () => {
              closeDialog();
              setIsSubmitting(false);
            }),
          );
        } else {
          dispatch(
            updateVolunteer(projectId, volunteer.id, values, () => {
              closeDialog();
              setIsSubmitting(false);
            }),
          );
        }
      }}
    >
      {({ submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput name="name" type="text" label="Name" className="mb-4" required />
          <TextInput name="email" type="email" label="Email" className="mb-4" required />
          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();

                setTimeout(() => {
                  resetForm();
                }, 100);
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                SUBMIT
                {isSubmitting && <ButtonSpinner />}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddVolunteerToVolunteerRoleForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  role: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    seats: PropTypes.number.isRequired,
  }).isRequired,
  volunteer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roleId: PropTypes.string.isRequired,
  }),
};

export default AddVolunteerToVolunteerRoleForm;
