import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
// library components
import { Formik, Form } from 'formik';
import { Button, makeStyles, Typography } from '@material-ui/core';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
// actions
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import { useInternalApiClient } from 'hooks/useInternalApiClient';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
}));

/**
 * Form used by facilitators to update the sessio title/topic
 */
const VolunteerSignupForm = ({ closeDialog, projectId, role, refreshPageData }) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const apiClient = useInternalApiClient();
  const classes = useStyles();
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { resetForm }) => {
        return apiClient.volunteer
          .submitVolunteerSignup(projectId, {
            ...values,
            roleId: role.id,
          })
          .then(() => {
            setIsSubmitSuccess(true);
            resetForm();
          })
          .catch(err => {
            dispatch(enqueueErrorSnackbar('Error submitting volunteer signup'));
            console.error('Error submitting volunteer signup: ', err);
          });
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          {isSubmitSuccess ? (
            <div className="mb-4">
              <Typography variant="body1">
                Volunteer signup submitted successfully! You will receive an email confirmation
                shortly.
              </Typography>
            </div>
          ) : (
            <>
              <TextInput name="name" type="text" label="Name" className="mb-4" required />
              <TextInput name="email" type="email" label="Email" className="mb-4" required />
            </>
          )}
          <div className={classes.dialogActions}>
            {!isSubmitSuccess && (
              <Button
                className={classes.cancelButton}
                disabled={isSubmitting}
                onClick={() => {
                  closeDialog();

                  setTimeout(() => {
                    resetForm();
                  }, 100);
                }}
              >
                CANCEL
              </Button>
            )}
            <div className={classes.saveButtonContainer}>
              {!isSubmitSuccess ? (
                <Button className={classes.saveButton} disabled={isSubmitting} onClick={submitForm}>
                  SUBMIT
                  {isSubmitting && <ButtonSpinner />}
                </Button>
              ) : (
                <>
                  <div />
                  <Button
                    className={classes.saveButton}
                    onClick={() => {
                      refreshPageData(500);

                      setTimeout(() => {
                        closeDialog();
                        setIsSubmitSuccess(false);
                      }, 100);
                    }}
                  >
                    CLOSE
                  </Button>
                </>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

VolunteerSignupForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  role: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    numberOfSlots: PropTypes.number.isRequired,
    slotsFilled: PropTypes.number.isRequired,
  }).isRequired,
  formattedDateTime: PropTypes.string.isRequired,
  refreshPageData: PropTypes.func.isRequired,
};

export default VolunteerSignupForm;
