import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import copy from 'copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';

// core components
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EmailIcon from '@material-ui/icons/Email';
import FolderIcon from '@material-ui/icons/Folder';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import VideocamIcon from '@material-ui/icons/Videocam';
import LinkIcon from '@material-ui/icons/Link';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/Code';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import PillButton from 'components/PillButton';

import { useDispatch, useSelector } from 'react-redux';
import { selectProject } from 'redux/selectors/staffing/getProject';
import {
  openAddJobToDealProjectDialog,
  openAddProjectToDealDialog,
  updateProject,
  deleteProject,
  reSyncCalendarEntry,
  callRegenerateRunOfShow,
  reSyncAttendeesListFromSheet,
  reSyncAttendeeMessagingConfig,
  overrideDefaultAttendeeMessagingConfig,
  openAddVolunteerRoleToProjectDialog,
} from 'redux/actions/staffingActions';
import {
  openInfoDialog,
  setInfoDialogContent,
  showAppOverlaySpinner,
} from 'redux/actions/facilitatorSessionActions';

import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';
import ProjectStatusView from 'components/Staffing/Projects/ProjectDetailCard/ProjectStatusView';
import ChecklistsSection from 'components/Staffing/Deals/Checklists';
import StaffingChecklistDialog from 'components/Dialogs/Staffing/StaffingChecklistDialog';
import ManageContractorsDialog from 'components/Dialogs/Staffing/ManageContractorsDialog';
import AddProjectToDealDialog from 'components/Dialogs/Staffing/AddProjectToDealDialog';
import JobSeatCard from 'components/Staffing/Projects/ProjectDetailCard/JobSeatCard';
import AddJobToDealProjectDialog from 'components/Dialogs/Staffing/AddJobToDealProjectDialog';

import { isEmpty, includes, compact, size, chain } from 'lodash';
import {
  PROJECT_STATUS_STATES,
  PROJECT_ERROR_STATUS_STATES,
  PROJECT_SERVICES,
  JOB_ROLES,
  PROJECT_LOCATION_TYPES,
} from 'constants/staffing';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import SendAttendeeMessageDialog from 'components/Dialogs/SendAttendeeMessageDialog';
import SendCohortMessageDialog from 'components/Dialogs/SendCohrtMessageDialog';
import { useFirestore } from 'react-redux-firebase';
import UploadAttendeesDialog from 'components/Dialogs/UploadAttendeesDialog';
import VolunteerRoleCard from 'components/Staffing/Projects/ProjectDetailCard/VolunteerRoleCard';
import AddVolunteerRoleToProjectDialog from 'components/Dialogs/Staffing/AddVolunteerRoleToProjectDialog';

const CREATE_JOB_PROJECT_STATUS_STATES = [
  PROJECT_STATUS_STATES.PENDING,
  PROJECT_STATUS_STATES.SCHEDULED,
  PROJECT_STATUS_STATES.IN_PROGRESS,
];

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #E5E5E5',
  },

  // Header
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid lightgrey',
    padding: '15px 15px',
  },
  header_left_right: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
  },

  // Menu button
  menuContainer: {
    position: 'relative',
  },
  menuButton: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: '#DBDBDB',
    marginLeft: 7,
  },

  // Detail Card
  bodyView: {
    width: '100%',
    padding: '20px 30px',
  },
  dealDetailCard: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
    border: '1px solid #E3E3E3',
    borderRadius: 4,
    padding: 20,
  },
  dealDetailCardHeader: {
    borderBottom: '1px solid #E1E1E1',
  },

  // Info Label & Description Text
  infoLabelContainer: {
    minHeight: 20,
    marginBottom: 10,
  },
  infoLabel: {
    fontSize: 11,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase',
  },
  infoTitleLG: {
    fontSize: 24,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '30px',
  },
  infoTitleMD: {
    fontSize: 18,
    fontWeight: '400',
    color: '#333333',
  },
  dealDescriptionText: {
    fontSize: 15,
    fontWeight: '300',
    lineHeight: '21px',
    marginBottom: 0,
  },

  // Deal Contact Button
  dealContactBtn: {
    display: 'inline-flex',
    padding: '3px 10px',
    backgroundColor: '#EBEBEB',
    border: '1px solid #D7D5D5',
    borderRadius: 99,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dealContactBtnText: {
    fontSize: 14,
    fontWeight: '400',
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginLeft: 5,
  },

  linkBtn: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  linkBtnText: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.palette.primary.main,
    marginLeft: 5,
    marginBottom: 0,
  },

  // Projects section
  projectsSection: {
    width: '100%',
    padding: '10px 0px',
  },

  noProjectsView: {
    padding: '50px 0px',
  },
  noProjectsText: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#898989',
    textAlign: 'center',
  },

  // Details
  checklistsSection: {
    borderBottom: '1px solid #E1E1E1',
    padding: '10px 0px',
  },

  // Jobs Section
  jobsSection: {
    width: '100%',
    padding: '10px 0px',
    margin: '10px 0px',
  },
  dealStatusButtonContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  dealStatusError: {
    alignSelf: 'end',
    fontSize: 12,
  },
  disabledLink: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  disabledLinkText: {
    color: theme.palette.text.disabled,
  },
  formButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    '& > button': {
      marginLeft: theme.spacing(2),
    },
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  uploadInstructions: {
    marginBottom: theme.spacing(2),
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
  attendeesFolderLink: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  formField: {
    marginRight: theme.spacing(2),
  },
  checkboxField: {
    marginTop: theme.spacing(2),
  },
  uploadLogsContainer: {
    height: '300px',
    overflowY: 'auto',
    marginBottom: theme.spacing(2),
    border: '1px solid #e0e0e0',
    padding: theme.spacing(1),
    '& p': {
      // Add this style for the log entries
      marginBottom: '0.25em', // Reduces space between log entries to 25% of normal
      lineHeight: 1.2, // Tightens the line height
    },
  },
  resyncMessage: {
    color: 'red',
    fontSize: 14,
    fontWeight: '500',
    marginTop: 8,
    marginBottom: 0,
  },
}));

// Add this function outside of the component, near the validateProjectAssetCreation function
const validateScheduleProject = (project, today) => {
  const errors = [];

  if (!project.startDate) {
    errors.push('Start date is missing');
  } else if (project.startDate < today) {
    errors.push('Start date must be in the future');
  }

  if (!project.startTime) {
    errors.push('Start time is missing');
  }

  if (!project.endDate) {
    errors.push('End date is missing');
  } else if (project.endDate < today) {
    errors.push('End date must be in the future');
  }

  if (!project.endTime) {
    errors.push('End time is missing');
  }

  if (project.isPublic && !project.signedLeadRoles.includes(JOB_ROLES.Facilitator)) {
    errors.push('A signed Lead Facilitator is required for public projects');
  }

  return {
    isValid: errors.length === 0,
    errorMessage: errors.join(', '),
  };
};

// Add this function outside of the component
const validateProjectAssetCreation = (project, today) => {
  const errors = [];

  if (!project.signedLeadRoles.includes(JOB_ROLES.Facilitator)) {
    errors.push('A Lead Facilitator must be signed');
  }

  if (
    project.locationType !== PROJECT_LOCATION_TYPES.in_person &&
    !project.allLeadsHaveVCZoomLicenses
  ) {
    errors.push('All Leads must have VC Zoom licenses');
  }

  if (project.numberOfSignedJobs !== size(project.jobs)) {
    errors.push('All jobs must be signed');
  }

  if (!project.startDate) {
    errors.push('Start date is missing');
  } else if (project.startDate < today) {
    errors.push('Start date must be in the future');
  }

  if (!project.endDate) {
    errors.push('End date is missing');
  } else if (project.endDate < today) {
    errors.push('End date must be in the future');
  }

  return {
    isValid: errors.length === 0,
    errorMessage: errors.join(', '),
  };
};

// Add this function outside of the component, near the other validation functions
const validateReadyToInvoice = (project, projectHasEnded) => {
  const errors = [];

  if (!projectHasEnded) {
    errors.push('Project must be in the past');
  }

  if (!project.checklistsCompleted) {
    errors.push('All checklists must be completed');
  }

  return {
    isValid: errors.length === 0,
    errorMessage: errors.join(', '),
  };
};

const ProjectDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirm = useConfirm();
  const db = useFirestore();
  const { projectId } = useParams();
  const history = useHistory();
  const getProject = selectProject();
  const project = useSelector(state => getProject(state, projectId));
  const [attendeeMessageDialogIsOpen, setAttendeeMessageDialogIsOpen] = useState(false);
  const [cohortMessageDialogIsOpen, setCohortMessageDialogIsOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const handleBackClick = () => {
    if (false && history.length > 1) {
      history.goBack();
    } else {
      history.push('/admin/staffing/projects');
    }
  };

  // TODO What's a more reliable way to determine if we should show the "Back" or "All Projects" text?
  const getBackText = () => {
    if (false && history.length > 1) {
      return 'Back';
    } else {
      return 'All Projects';
    }
  };

  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
  };

  if (!project) return null;

  const isProgram = project.serviceType === PROJECT_SERVICES.Program;
  const isPublic = project.isPublic;
  const isCertification = project.serviceType === PROJECT_SERVICES.Certification;
  const currentStatusIndex = Object.values(PROJECT_STATUS_STATES).indexOf(project.status);

  const contactEmail = `${project.contactEmail || project.dealContactEmail}`;
  const contactEmailLink = `mailto:${contactEmail}`;
  const contactName = `${project.contactName || project.dealContactName}`;

  const toggleAttendeeMessageDialog = () =>
    setAttendeeMessageDialogIsOpen(!attendeeMessageDialogIsOpen);
  const toggleCohortMessageDialog = () => setCohortMessageDialogIsOpen(!cohortMessageDialogIsOpen);

  const reSyncCalendar = () => {
    confirm({
      description: `This will regenerate the ROS, update the Zoom meeting, & re-sync the google calendar entry with the data from this project and notify all attendees on the calendar event. Have you confirmed that there is no overlapping zoom meetings for zoom user ${project.zoomUserEmail}?`,
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        reSyncCalendarEntry(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const regenerateRunOfShow = () => {
    confirm({
      description: `This will regenerate the ROS. The existing ROS will be deleted, unless there have been updates since it was created.`,
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        callRegenerateRunOfShow(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const reSyncAttendeesList = () => {
    confirm({
      description:
        'This will sync the Attendees List with the project and add attendees to the calendar event. It will also create attendee assets, add attendees to Mural and Circle where applicable, and enabled the scheduled attendee messaging. After the first time a sync is run it will also run nightly until the project start date.',
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        reSyncAttendeesListFromSheet(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const reSyncMessagingConfig = () => {
    confirm({
      description:
        'This will sync the Attendee Messaging Config for the project from the messaging config google sheet according to the project settings',
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        reSyncAttendeeMessagingConfig(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const overrideDefaultMessagingConfig = () => {
    confirm({
      description:
        'This will override the default messaging config by copying the default config into the project folder. The copied config will be used for the project moving forward instead of the default config. Previously sent messages will not be re-sent as long as they have the same ID.',
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        overrideDefaultAttendeeMessagingConfig(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const copyAttendeesJson = async () => {
    const _attendees = await (await db.collection('projectAttendees').doc(projectId).get()).data()
      ?.attendees;

    if (!_attendees) {
      dispatch(enqueueErrorSnackbar('No attendee data found'));
      return;
    }

    copy(JSON.stringify(_attendees, null, 2), {
      onCopy: () => {
        dispatch(enqueueSuccessSnackbar('Attendees Data Copied'));
      },
      format: 'text/plain',
    });
  };

  const handleOpenUploadDialog = () => {
    setUploadDialogOpen(true);
  };

  const menuOptions = compact([
    {
      title: 'Edit Project',
      onItemClick: () => {
        dispatch(openAddProjectToDealDialog(true, project.dealId, project.id));
      },
      disabled: project.isCreatingAttendeeAssets,
    },
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.SCHEDULED) && !isProgram
      ? {
          title: 'Re-sync Calendar Event Info',
          onItemClick: () => reSyncCalendar(),
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    currentStatusIndex >=
    Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.SCHEDULED)
      ? {
          title: 'Regenerated Run of Show',
          onItemClick: () => regenerateRunOfShow(),
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) && !isProgram
      ? {
          title: project.hasSyncedAttendees ? 'Re-sync Attendees' : 'Sync Attendees',
          onItemClick: () => reSyncAttendeesList(),
          disabled: project.isCreatingAttendeeAssets,
          loading: project.isCreatingAttendeeAssets,
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.SCHEDULED) && isPublic
      ? {
          title: 'Upload Attendees to HS',
          onItemClick: handleOpenUploadDialog,
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    !isProgram && !project.customMessagingConfigSheet?.id
      ? {
          title: 'Override Default Messaging Config',
          onItemClick: () => overrideDefaultMessagingConfig(),
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) && !isProgram
      ? {
          title: 'Re-Sync Messaging Config',
          onItemClick: () => reSyncMessagingConfig(),
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    project.hasSyncedAttendees
      ? {
          title: 'Copy Attendees Data',
          onItemClick: () => copyAttendeesJson(),
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    project.hasSyncedAttendees
      ? {
          title: 'Manual Attendee Message',
          onItemClick: () => toggleAttendeeMessageDialog(),
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    !isProgram &&
    isCertification &&
    project.hasSyncedAttendees
      ? {
          title: 'Manual Cohort Message',
          onItemClick: () => toggleCohortMessageDialog(),
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    //hide the Previous Stage button when the Project is pending/closed
    !includes(
      [
        PROJECT_STATUS_STATES.PENDING,
        PROJECT_STATUS_STATES.CLOSED,
        PROJECT_ERROR_STATUS_STATES.CREATING_ASSETS,
      ],
      project?.status,
    )
      ? {
          title: 'Previous Stage',
          onItemClick: () => {
            dispatch(
              updateProject(project.id, {
                status: Object.values(PROJECT_STATUS_STATES)[currentStatusIndex - 1],
              }),
            );
          },
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    // {
    //   title: "Close",
    //   onItemClick: () => {
    //     dispatch(updateProject(project.id, { status: PROJECT_STATUS_STATES.CLOSED }));
    //   },
    // },
    {
      title: 'Delete Project',
      onItemClick: () => {
        confirm({
          description: `You are about to delete this project. Are you sure?`,
        }).then(() => {
          dispatch(deleteProject(project.id, project.dealId));
          history.goBack();
        });
      },
      disabled: project.isCreatingAttendeeAssets,
    },
  ]);

  if (!project) {
    return null;
  }

  const canAddJob =
    project.startDate &&
    project.endDate &&
    includes(CREATE_JOB_PROJECT_STATUS_STATES, project.status);
  const addJobTooltip = canAddJob
    ? ''
    : 'Project must be initialized and have a start date and end date to add a job';

  return (
    <div className={cx(classes.main)}>
      {/* Header */}
      <div className={cx(classes.header)}>
        <div className={classes.header_left_right}>
          <Button
            variant="text"
            className={'d-flex flex-start'}
            color="primary"
            onClick={handleBackClick}
          >
            <ArrowBackIosIcon fontSize="small" />
            <p className="m-0 text-uppercase">{getBackText()}</p>
          </Button>
        </div>
        <h4 className="font-weight-bold">{project.eventName}</h4>

        <div className={classes.header_left_right}>
          <ProjectStatusButton project={project} />
          <SelectMenu
            menuContainerClassName={classes.menuContainer}
            menuBtnClassName={classes.menuButton}
            options={menuOptions}
          />
        </div>
      </div>

      <div className={classes.bodyView}>
        {/* Deal Details  */}
        <div className={classes.dealDetailCard}>
          <div className={classes.dealDetailCardHeader}>
            <Grid container spacing={5} className="mb-1">
              <Grid item xs={12} sm={6} md={6}>
                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Project Name</p>
                </div>
                <p className={classes.infoTitleLG}>{project.eventName}</p>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Deal Name</p>
                </div>
                <Link
                  to={`/admin/staffing/deals/${project.dealId}`}
                  className={classes.infoTitleLG}
                >
                  {project.dealName}
                </Link>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Company Name</p>
                </div>
                <p className={classes.infoTitleLG}>{project.clientName}</p>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>About</p>
                </div>

                <p className={classes.dealDescriptionText}>{project.notes}</p>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Project Stage</p>
                    </div>
                    <ProjectStatusView status={project.status} statusError={project.statusError} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Amount</p>
                    </div>
                    <p className={classes.infoTitleLG}>{project.formattedProjectContractValue}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Project Contact</p>
                    </div>

                    <div className={classes.dealContactBtn}>
                      <EmailIcon fontSize="small" color="primary" />
                      <a href={contactEmailLink} target="_blank">
                        <p className={classes.dealContactBtnText}>{contactName}</p>
                      </a>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={12}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>More Details</p>
                    </div>

                    <div className="d-flex align-items-center flex-wrap">
                      <PillButton
                        type="plainText"
                        text={project.isPublic ? 'Public' : 'Private'}
                        disabled
                      />
                      {project.isCreatingAttendeeAssets && !project.errorCreatingAttendeeAssets && (
                        <PillButton
                          type="plainText"
                          text="Creating Attendee Assets"
                          disabled
                          loading
                        />
                      )}
                      {project.errorCreatingAttendeeAssets && (
                        <PillButton
                          type="plainText"
                          text="Creating Attendee Assets"
                          disabled
                          hasError
                          tooltipText={`Error: ${project.errorCreatingAttendeeAssets}`}
                        />
                      )}
                      {project.isFree && <PillButton type="plainText" text="Free" disabled />}
                      {project.venue && (
                        <PillButton type="plainText" text={project.venue} Icon={LocationOnIcon} />
                      )}
                      {['hybrid', 'virtual'].includes(project.locationType) && project.zoomLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.zoomLink}
                          text="Zoom Link"
                          Icon={VideocamIcon}
                        />
                      )}
                      {project.projectBrief?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.projectBrief.webViewLink}
                          text="Project Brief"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.checklistDoc?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.checklistDoc.webViewLink}
                          text="Checklist"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.vcEvent?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.vcEvent.webViewLink}
                          text="VC Event"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.circleEvent?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.circleEvent.webViewLink}
                          text="Circle Event"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.volunteerRoles && Object.keys(project.volunteerRoles).length > 0 && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={`${process.env.REACT_APP_URL}/volunteer-signup/${project.id}`}
                          text="Volunteer Signup"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.runOfShowDoc?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.runOfShowDoc.webViewLink}
                          text="ROS"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.calendarEvent?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.calendarEvent.webViewLink}
                          text="Calendar"
                          Icon={EventIcon}
                        />
                      )}
                      {project.attendeesSheet?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.attendeesSheet.webViewLink}
                          text="Attendees"
                          Icon={GroupIcon}
                        />
                      )}
                      {project.calendarEvent?.id && (
                        <PillButton
                          type="clickToCopy"
                          clickToCopyContent={project.calendarEvent?.webViewLink.split('eid=')[1]}
                          text="Cal Event ID"
                          Icon={CodeIcon}
                        />
                      )}
                      {project.projectFolder?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.projectFolder.webViewLink}
                          text="Project Folder"
                          Icon={FolderIcon}
                        />
                      )}
                      {project.customMessagingConfigSheet?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.customMessagingConfigSheet.webViewLink}
                          text="Messaging Config (Custom)"
                          Icon={SettingsIcon}
                        />
                      )}
                      {!project.customMessagingConfigSheet?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={process.env.REACT_APP_DEFAULT_MESSAGING_CONFIG_SHEET_URL}
                          text="Messaging Config (Default)"
                          Icon={SettingsIcon}
                        />
                      )}
                    </div>
                    {project.remindUserToRunResyncCalendar && (
                      <p className={classes.resyncMessage}>Needs Resync Cal</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={5} className="mb-1"></Grid>
          </div>

          <div className={classes.checklistsSection}>
            <div className={classes.infoLabelContainer}>
              <p className={classes.infoLabel}>Checklists</p>
            </div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <ChecklistsSection id={projectId} />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Location Type</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.displayLocationType}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Event Type</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.eventType}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Start Date</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedStartDate}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Start Time</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedStartTime}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Methodology</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.projectMethodologyName}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Topic</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.eventTopic}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>End Date</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedEndDate}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>End Time</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedEndTime}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Service Type</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.serviceType}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Region</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.eventRegion}</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Jobs Section */}
        <div className={classes.jobsSection}>
          <div className="d-flex align-items-center justify-content-between">
            <div className={classes.infoLabelContainer}>
              <p className={classes.infoLabel}>Jobs</p>
            </div>

            <Tooltip title={addJobTooltip} placement="top">
              <div
                className={cx(classes.linkBtn, { [classes.disabledLink]: !canAddJob })}
                onClick={() => {
                  if (canAddJob) {
                    dispatch(openAddJobToDealProjectDialog(true, project.id));
                  }
                }}
              >
                <AddCircleIcon fontSize="small" color={canAddJob ? 'primary' : 'disabled'} />
                <p
                  className={cx(classes.linkBtnText, {
                    [classes.disabledLinkText]: !canAddJob,
                  })}
                >
                  Add Job
                </p>
              </div>
            </Tooltip>
          </div>
          {isEmpty(project.jobs) && (
            <div className={cx(classes.noProjectsView, 'd-flex justify-content-center')}>
              <p className={classes.noProjectsText}>
                No jobs yet. Once jobs have been added you'll be able to track the status here.
              </p>
            </div>
          )}
          <Grid container spacing={5}>
            {chain(project.jobs)
              .orderBy(['jobName'])
              .map(job => {
                return (
                  <Grid item xs={6} key={job.jobId}>
                    <JobSeatCard jobId={job.jobId} projectId={project.id} isProjectActive={true} />
                  </Grid>
                );
              })
              .value()}
          </Grid>
        </div>

        {/* Volunteers Section */}
        <div className={classes.volunteersSection}>
          <div className="d-flex align-items-center justify-content-between">
            <div className={classes.infoLabelContainer}>
              <p className={classes.infoLabel}>Volunteer Roles</p>
            </div>

            <div
              className={cx(classes.linkBtn)}
              onClick={() => {
                if (canAddJob) {
                  dispatch(openAddVolunteerRoleToProjectDialog(true, project.id));
                }
              }}
            >
              <AddCircleIcon fontSize="small" color="primary" />
              <p className={cx(classes.linkBtnText)}>
                Add Volunteer Role
              </p>
            </div>
          </div>
          {isEmpty(project.volunteerRoles) && (
            <div className={cx(classes.noProjectsView, 'd-flex justify-content-center')}>
              <p className={classes.noProjectsText}>
                No volunteer roles yet. Once volunteer roles have been added you'll be able to track
                the status here.
              </p>
            </div>
          )}
          <Grid container spacing={5}>
            {chain(project.volunteerRoles)
              .orderBy(['displayOrder', 'name'])
              .map(volunteerRole => {
                return (
                  <Grid item xs={6} key={volunteerRole.id}>
                    <VolunteerRoleCard volunteerRoleId={volunteerRole.id} projectId={project.id} />
                  </Grid>
                );
              })
              .value()}
          </Grid>
        </div>
      </div>

      <StaffingChecklistDialog />
      <AddJobToDealProjectDialog />
      <AddProjectToDealDialog />
      <ManageContractorsDialog />
      <AddVolunteerRoleToProjectDialog />
      {project.hasSyncedAttendees && (
        <>
          <SendAttendeeMessageDialog
            isOpen={attendeeMessageDialogIsOpen}
            handleClose={toggleAttendeeMessageDialog}
            projectId={project.id}
            projectAttendeeMessages={project.attendeeMessages || []}
          />
          <SendCohortMessageDialog
            isOpen={cohortMessageDialogIsOpen}
            handleClose={toggleCohortMessageDialog}
            projectId={project.id}
            projectAttendeeMessages={project.attendeeMessages || []}
          />
        </>
      )}
      <UploadAttendeesDialog
        isOpen={uploadDialogOpen}
        handleClose={closeUploadDialog}
        project={project}
      />
    </div>
  );
};

const ProjectStatusButton = props => {
  const { project } = props;
  const { status, id } = project;
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirm = useConfirm();

  const currentDate = new Date();
  const timeNowCT = formatInTimeZone(currentDate, 'America/Chicago', 'HH:mm:ss');
  const today = formatInTimeZone(currentDate, 'America/Chicago', 'yyyy-MM-dd');
  const nowDateTimeCT = `${today}T${timeNowCT}`;
  const projectEndDateTime = `${project.endDate}T${project.endTime}`;

  const projectHasEnded = nowDateTimeCT >= projectEndDateTime;

  const isProgram = project.serviceType === PROJECT_SERVICES.Program;

  const getButtonText = () => {
    switch (status) {
      case PROJECT_ERROR_STATUS_STATES.INITIALIZING:
        return 'Initialize';
      case PROJECT_STATUS_STATES.PENDING:
        return isProgram ? 'Create Assets' : 'Schedule';
      case PROJECT_ERROR_STATUS_STATES.ADDING_CALENDAR:
        return 'Schedule';
      case PROJECT_STATUS_STATES.ADDING_CALENDAR:
      case PROJECT_STATUS_STATES.SCHEDULED:
      case PROJECT_ERROR_STATUS_STATES.CREATING_ASSETS:
        return 'Create Assets';
      case PROJECT_STATUS_STATES.IN_PROGRESS:
        return 'Ready to Invoice';
      case PROJECT_STATUS_STATES.READY_TO_INVOICE:
        return 'Invoice';
      case PROJECT_ERROR_STATUS_STATES.READY_TO_INVOICE:
        return 'Ready to Invoice';
      case PROJECT_STATUS_STATES.INVOICING:
        return 'Paid';
      default:
        return '';
    }
  };

  const handleProjectStatus = () => {
    //if the Current Project state is in 'Error', display a confirmation..
    if (includes(Object.values(PROJECT_ERROR_STATUS_STATES), status)) {
      confirm({
        description:
          'You are attempting to re-run this step after an error. Please confirm the error has been corrected.',
      }).then(() => {
        //Update the Project to the equivalent Project Status state.
        const key = Object.keys(PROJECT_ERROR_STATUS_STATES).find(
          key => PROJECT_ERROR_STATUS_STATES[key] === status,
        ); //get the Key, e.g 'initializing-error' equals 'INITIALIZING'
        dispatch(updateProject(id, { status: PROJECT_STATUS_STATES[key] }));
      });
      return;
    }

    if (status === PROJECT_STATUS_STATES.PENDING && !isProgram) {
      const { isValid, errorMessage } = validateScheduleProject(project, today);

      if (!isValid) {
        dispatch(
          setInfoDialogContent({
            title: 'Cannot schedule project',
            content: `Please address the following: ${errorMessage}.`,
          }),
        );
        dispatch(openInfoDialog(true));
      } else {
        dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.ADDING_CALENDAR }));
      }
    } else if (
      (status === PROJECT_STATUS_STATES.PENDING && isProgram) ||
      status === PROJECT_STATUS_STATES.SCHEDULED ||
      status === PROJECT_ERROR_STATUS_STATES.CREATING_ASSETS
    ) {
      const { isValid, errorMessage } = validateProjectAssetCreation(project, today);

      if (!isValid) {
        dispatch(
          setInfoDialogContent({
            title: 'Cannot create project assets',
            content: `Please address the following: ${errorMessage}.`,
          }),
        );
        dispatch(openInfoDialog(true));
      } else {
        if (!project.signedLeadRoles.includes(JOB_ROLES.Scribe)) {
          confirm({
            description: 'You are about to create assets without a signed Lead Scribe',
          }).then(() => {
            dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.CREATING_ASSETS }));
          });
        } else {
          dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.CREATING_ASSETS }));
        }
      }
    } else if (status === PROJECT_STATUS_STATES.IN_PROGRESS) {
      const { isValid, errorMessage } = validateReadyToInvoice(project, projectHasEnded);

      if (!isValid) {
        dispatch(
          setInfoDialogContent({
            title: 'Cannot Mark as Ready to Invoice',
            content: `Please address the following: ${errorMessage}.`,
          }),
        );
        dispatch(openInfoDialog(true));
      } else {
        confirm({
          description:
            "You are attempting manually change the project state to 'Ready to Invoice'. This will create a followup tasks for the project in HubSpot and notify ap@voltagecontrol.com.",
        })
          .then(() => {
            dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.READY_TO_INVOICE }));
          })
          .catch(e => {});
      }
    } else if (status === PROJECT_STATUS_STATES.READY_TO_INVOICE) {
      dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.INVOICING }));
    } else if (status === PROJECT_STATUS_STATES.INVOICING) {
      if (project.numberOfPaidJobs !== size(project.jobs)) {
        dispatch(
          setInfoDialogContent({
            title: 'Cannot close project',
            content: 'All jobs must be marked paid to close projects.',
          }),
        );
        dispatch(openInfoDialog(true));
      } else {
        dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.CLOSED }));
      }
    }
  };

  //hide the button if the project is in the below status
  if (
    includes(
      [
        PROJECT_STATUS_STATES.INITIALIZING,
        PROJECT_STATUS_STATES.CREATING_ASSETS,
        PROJECT_STATUS_STATES.CLOSED,
        PROJECT_STATUS_STATES.ADDING_CALENDAR,
      ],
      status,
    )
  ) {
    return <div className="flex-1"></div>;
  }

  return (
    <div className={classes.dealStatusButtonContainer}>
      <Button
        variant="contained"
        color="primary"
        className="text-uppercase px-4 py-1 ml-auto"
        size="small"
        disabled={status === PROJECT_STATUS_STATES.ADDING_CALENDAR}
        onClick={handleProjectStatus}
      >
        {getButtonText()}
      </Button>
    </div>
  );
};

export default ProjectDetails;
