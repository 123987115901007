import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import FormDialog from 'components/Dialogs/FormDialog';
import VolunteerSignupForm from 'components/Forms/Staffing/VolunteerSignupForm';

export default function VolunteerSignupCard({
  role,
  isFilled,
  projectId,
  formattedDateTime,
  refreshPageData,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="w-100 border border-dark rounded-lg p-2 mb-3">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-2">
            <Typography variant="body1" className="mr-2">
              <span className="fw-500">Role:</span> {role.title}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={isFilled(role.id)}
              onClick={toggleDialog}
            >
              {isFilled(role.id) ? 'Filled' : 'Sign Up'}
            </Button>
          </div>
        </div>
        <Typography variant="body1">{role.description}</Typography>
      </div>
      <FormDialog
        isOpen={isOpen}
        handleClose={toggleDialog}
        title={`${role.title} - ${formattedDateTime}`}
      >
        <VolunteerSignupForm
          closeDialog={toggleDialog}
          projectId={projectId}
          role={role}
          refreshPageData={refreshPageData}
        />
      </FormDialog>
    </>
  );
}
