import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { map, compact, filter, values } from 'lodash';

// core components
import { Card, CardContent, Divider, Tooltip, makeStyles } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';

import { ReactComponent as StickyNoteIcon } from '../../../../assets/img/sticky_note.svg';

//cr components
import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';

//actions & selectors
import {
  openAddJobToDealProjectDialog,
  openCloneJobDialog,
  openManageContractorsDialog,
  deleteJob,
  removeContractorFromJob,
  updateAssigneeInJob,
  sendContractReminder,
  createAndSendContract,
} from 'redux/actions/staffingActions';
import { selectJob } from 'redux/selectors/staffing/getProject';

import './jobSeatCard.scss';

const useStyles = makeStyles({
  jobTitle: {
    fontSize: 16,
    fontWeight: 'bolder',
    color: 'black',
  },
  jobSubtitle: {
    fontSize: 15,
    fontWeight: 'bolder',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
  },
  jobSeatItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0',
  },
  controllerButton: {
    fontSize: 14,
  },
  signedLabel: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.75,
    padding: '0px 10px',
    color: '#898989',
  },
  rejectedWrapper: {
    display: 'flex',
    gap: 10,
  },
  rejectedIcon: {
    margin: 'auto',
    marginLeft: 6,
    cursor: 'pointer',
    color: '#898989',
  },
});

const statusDisplay = {
  signed: 'Signed',
  paid: 'Paid',
  accepted: 'Accepted',
};

const JobSeatCard = ({ jobId, projectId, isProjectActive }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const getJob = selectJob();
  const currentJob = useSelector(state => getJob(state, projectId, jobId));
  const availableSeats =
    filter(
      values(currentJob.assignees),
      assignee => assignee.status === 'signed' || assignee.status === 'paid',
    ).length < currentJob.seats;

  // TODO: is there a better way that might notify the user???
  const assignStaff = !availableSeats
    ? null
    : {
        title: 'Assign Staff',
        onItemClick: () => {
          dispatch(openManageContractorsDialog(true, projectId, jobId));
        },
      };

  // Select Menu Options
  const selectOptions = compact([
    assignStaff,
    {
      title: 'Edit Job',
      onItemClick: () => {
        dispatch(openAddJobToDealProjectDialog(true, projectId, jobId));
      },
    },
    {
      title: 'Clone Job',
      onItemClick: () => {
        dispatch(openCloneJobDialog(true, projectId, jobId));
      },
    },
    {
      title: 'Copy Link',
      onItemClick: () => alert('Copy Link'),
    },
    {
      title: 'Delete',
      onItemClick: () => {
        confirm({
          description: `You are about to delete this Job. Are you sure?`,
        }).then(() => {
          dispatch(deleteJob(projectId, jobId));
        });
      },
    },
  ]);

  /**
   * UnAssign contractor from Job
   * @param {Object} contractor
   */
  const handleUnAssignContractor = contractor => {
    confirm({
      description: `Are you sure you want to unassign ${contractor.displayName} from Job?`,
    }).then(() => {
      dispatch(removeContractorFromJob(projectId, jobId, contractor.contractorId));
    });
  };

  const handleResendInvitations = (contractorId = '') => {
    confirm({
      description: `Are you sure you want to re-send invitations to Pending Staff?`,
    }).then(() => {
      if (currentJob && contractorId) {
        dispatch(updateAssigneeInJob(projectId, jobId, contractorId, { sentInvite: false }));
      }
    });
  };

  const handleContractorSignContract = seat => {
    confirm({
      description: `Are you sure you want to mark this contract as signed? This does not affect anything in HelloSign. It only updates the status of the contract in our system.`,
    }).then(() => {
      dispatch(updateAssigneeInJob(projectId, jobId, seat.id, { status: 'signed' }));
    });
  };

  const handleContractorPaid = seat => {
    confirm({
      description: `Are you sure you want to mark this contract as paid?`,
    }).then(() => {
      dispatch(updateAssigneeInJob(projectId, jobId, seat.id, { status: 'paid' }));
    });
  };

  const handleContractorAccepted = seat => {
    confirm({
      description: `Are you sure you want to accept this job on behalf of this Staff?`,
    }).then(() => {
      dispatch(updateAssigneeInJob(projectId, jobId, seat.id, { status: 'accepted' }));
    });
  };

  const handleContractorSendContract = seat => {
    confirm({
      description: `Are you sure you want to send the Create and Send the Contract?`,
    }).then(() => {
      dispatch(createAndSendContract(projectId, seat.id, jobId, seat.email));
    });
  };

  const handleContractorResendContract = seat => {
    confirm({
      description: `Are you sure you want to send the Contract Reminder?`,
    }).then(() => {
      dispatch(sendContractReminder(seat.email, seat.helloSignatureRequestId));
    });
  };

  if (!currentJob) {
    return null;
  }

  const {
    jobRole,
    jobName,
    seats,
    startDate,
    endDate,
    description,
    acceptedInvitations,
    pendingInvitations,
    rejectedInvitations,
    displayFee,
  } = currentJob;

  return (
    <Card className={cx('job-seat-card position-relative')}>
      <CardContent>
        <div>
          <p className={classes.jobTitle}>
            {jobName}, {jobRole} ({seats} seats)
          </p>

          <p className={classes.jobSubtitle}>
            {currentJob.isLead && (
              <span className="lead-pill bg-success">
                <span>Lead</span>
              </span>
            )}
            {displayFee}, {moment(startDate).format('MM/DD/YYYY')} -{' '}
            {moment(endDate).format('MM/DD/YYYY')}
          </p>
          <p>{description}</p>
        </div>

        {isProjectActive && <SelectMenu options={selectOptions} menuContainerClassName="mx-1" />}

        <Divider />

        <div className="">
          {map(acceptedInvitations, (seat, index) => (
            <div key={`accepted-invitation-${index}`}>
              <div className={cx(classes.jobSeatItemWrapper)}>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-1 font-weight-bold text-secondary">Seat {index + 1}:</p>
                  <p className="mb-0">
                    <a href={`/admin/staffing/contractors/${seat.id}`}>
                      {seat.displayName} ({seat.email})
                    </a>
                  </p>
                </div>

                {isProjectActive && (
                  <div className="d-flex flex-row  align-items-center">
                    <span>{statusDisplay[seat.status]}</span>
                    {(seat.status !== 'paid' || seat.staffType === 'Employee') && (
                      <div className="position-relative right-0">
                        <SelectMenu
                          options={compact([
                            seat.status === 'accepted' &&
                              availableSeats && {
                                title: 'Mark as Signed',
                                onItemClick: () => {
                                  handleContractorSignContract(seat);
                                },
                              },
                            seat.status === 'accepted' &&
                              availableSeats &&
                              !seat.helloSignatureRequestId && {
                                title: 'Create and Send Contract',
                                onItemClick: () => {
                                  handleContractorSendContract(seat);
                                },
                              },
                            seat.status === 'accepted' &&
                              availableSeats &&
                              seat.helloSignatureRequestId && {
                                title: 'Resend Contract',
                                onItemClick: () => {
                                  handleContractorResendContract(seat);
                                },
                              },
                            seat.status === 'signed' && {
                              title: 'Mark Paid',
                              onItemClick: () => {
                                handleContractorPaid(seat);
                              },
                            },
                            {
                              title: 'Unassign',
                              onItemClick: () => {
                                handleUnAssignContractor(seat);
                              },
                            },
                          ])}
                          menuContainerClassName="position-relative"
                          menuBtnClassName="px-1 py-0"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Divider />
            </div>
          ))}
        </div>
        <div className="">
          {map(pendingInvitations, (contractor, index) => (
            <div key={`pending-invitation-${index}`}>
              <div className={cx(classes.jobSeatItemWrapper)}>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-1 text-secondary">
                    <a href={`/admin/staffing/contractors/${contractor.id}`}>
                      {contractor.displayName} ({contractor.email})
                    </a>
                  </p>
                </div>

                <div className="d-flex flex-row  align-items-center">
                  <span>Pending</span>
                  <div className="position-relative right-0">
                    <SelectMenu
                      options={[
                        {
                          title: 'Mark as Accepted',
                          onItemClick: () => {
                            handleContractorAccepted(contractor);
                          },
                        },
                        {
                          title: 'Resend Invitation',
                          onItemClick: () => {
                            handleResendInvitations(contractor.id);
                          },
                        },
                        {
                          title: 'Unassign',
                          onItemClick: () => {
                            handleUnAssignContractor(contractor);
                          },
                        },
                      ]}
                      menuContainerClassName="position-relative"
                      menuBtnClassName="px-1 py-0"
                    />
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>

        <div className="">
          {map(rejectedInvitations, (contractor, index) => (
            <div key={`rejected-invitation-${index}`}>
              <div className={cx(classes.jobSeatItemWrapper)}>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-1 text-secondary">
                    <a href={`/admin/staffing/contractors/${contractor.id}`}>
                      {contractor.displayName} ({contractor.email})
                    </a>
                  </p>
                  {contractor.status === 'rejected' && (
                    <Tooltip arrow title={contractor.notes}>
                      <StickyNoteIcon className={classes.rejectedIcon} />
                    </Tooltip>
                  )}
                </div>

                <div className="d-flex flex-row  align-items-center">
                  <span>Pending</span>
                  <div className="position-relative right-0">
                    <SelectMenu
                      options={[]}
                      menuContainerClassName="position-relative"
                      menuBtnClassName="px-1 py-0"
                    />
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default JobSeatCard;
