import React from 'react';
import cx from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/errorPageStyles.js';

const useStyles = makeStyles(styles);

export default function Page404() {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem xs={12}>
          <h1 className={cx(classes.title, 'text-dark')}>404</h1>
          <h2 className={cx(classes.subTitle, 'mb-5 text-dark')}>Page not found</h2>
          <Button
            variant="contained"
            color="primary"
            href={`${window.location.protocol}//${window.location.host}`}
          >
            Return to Safety
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
