import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { map, compact, filter, values } from 'lodash';

// core components
import { Card, CardContent, Divider, Tooltip, makeStyles } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';

//cr components
import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';

//actions & selectors

import { selectVolunteerRole } from 'redux/selectors/staffing/getProject';

import './jobSeatCard.scss';
import {
  deleteVolunteer,
  deleteVolunteerRole,
  openAddVolunteerRoleToProjectDialog,
} from 'redux/actions/staffingActions';
import FormDialog from 'components/Dialogs/FormDialog';
import AddVolunteerToVolunteerRoleForm from 'components/Forms/Staffing/AddVolunteerToVolunteerRoleForm';

const useStyles = makeStyles({
  jobTitle: {
    fontSize: 16,
    fontWeight: 'bolder',
    color: 'black',
  },
  jobSubtitle: {
    fontSize: 15,
    fontWeight: 'bolder',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
  },
  jobSeatItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0',
  },
  controllerButton: {
    fontSize: 14,
  },
  signedLabel: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.75,
    padding: '0px 10px',
    color: '#898989',
  },
  rejectedWrapper: {
    display: 'flex',
    gap: 10,
  },
  rejectedIcon: {
    margin: 'auto',
    marginLeft: 6,
    cursor: 'pointer',
    color: '#898989',
  },
});

const VolunteerRoleCard = ({ volunteerRoleId, projectId }) => {
  const [volunteerDialogOpen, setVolunteerDialogOpen] = useState(false);
  const [volunteerDialogVolunteer, setVolunteerDialogVolunteer] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const getVolunteerRole = selectVolunteerRole();
  const currentVolunteerRole = useSelector(state =>
    getVolunteerRole(state, projectId, volunteerRoleId),
  );

  const availableSeats = currentVolunteerRole.volunteers.length < currentVolunteerRole.seats;

  const addVolunteer = !availableSeats
    ? null
    : {
        title: 'Add Volunteer',
        onItemClick: () => {
          toggleVolunteerDialog();
        },
      };

  // Select Menu Options
  const selectOptions = compact([
    addVolunteer,
    {
      title: 'Edit',
      onItemClick: () => {
        dispatch(openAddVolunteerRoleToProjectDialog(true, projectId, volunteerRoleId));
      },
    },
    {
      title: 'Delete',
      onItemClick: () => {
        confirm({
          description: `You are about to delete this Volunteer Role. Are you sure?`,
        }).then(() => {
          dispatch(deleteVolunteerRole(projectId, volunteerRoleId));
        });
      },
    },
  ]);

  const toggleVolunteerDialog = () => {
    setVolunteerDialogOpen(!volunteerDialogOpen);
  };

  const handleEditVolunteer = volunteer => {
    setVolunteerDialogVolunteer(volunteer);
    toggleVolunteerDialog();
  };

  /**
   * UnAssign contractor from Job
   * @param {Object} contractor
   */
  const handleRemoveVolunteer = volunteer => {
    confirm({
      description: `Are you sure you want to remove ${volunteer.name} from this Volunteer Role?`,
    }).then(() => {
      dispatch(deleteVolunteer(projectId, volunteer.id));
    });
  };

  if (!currentVolunteerRole) {
    return null;
  }

  const { title, description, seats, volunteers, displayOrder } = currentVolunteerRole;

  return (
    <>
      <Card className={cx('job-seat-card position-relative')}>
        <CardContent>
          <div>
            <p className={classes.jobTitle}>
              {title} ({seats} seats)
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Description:</span> {description}
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Display Order:</span> {displayOrder}
            </p>
          </div>

          <SelectMenu options={selectOptions} menuContainerClassName="mx-1" />

          {volunteers.length > 0 && <Divider />}

          <div className="">
            {map(volunteers, (volunteer, index) => (
              <div key={`accepted-invitation-${index}`}>
                <div className={cx(classes.jobSeatItemWrapper)}>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-1 font-weight-bold text-secondary">Seat {index + 1}:</p>
                    <p className="mb-0">
                      {volunteer.name} (<a href={`mailto:${volunteer.email}`}>{volunteer.email}</a>)
                    </p>
                  </div>

                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <div className="position-relative right-0">
                      <SelectMenu
                        options={compact([
                          {
                            title: 'Edit',
                            onItemClick: () => {
                              handleEditVolunteer(volunteer);
                            },
                          },
                          {
                            title: 'Delete',
                            onItemClick: () => {
                              handleRemoveVolunteer(volunteer);
                            },
                          },
                        ])}
                        menuContainerClassName="position-relative"
                        menuBtnClassName="px-1 py-0"
                      />
                    </div>
                  </div>
                </div>
                <Divider />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
      <FormDialog
        isOpen={volunteerDialogOpen}
        handleClose={() => {
          toggleVolunteerDialog();
          setVolunteerDialogVolunteer(null);
        }}
        title="Edit Volunteer Seat"
      >
        <AddVolunteerToVolunteerRoleForm
          closeDialog={toggleVolunteerDialog}
          projectId={projectId}
          role={currentVolunteerRole}
          volunteer={volunteerDialogVolunteer}
        />
      </FormDialog>
    </>
  );
};

export default VolunteerRoleCard;
