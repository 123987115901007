import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// core components
import { makeStyles } from '@material-ui/core/styles';
import PickerSelect from 'components/Staffing/PickerSelect/PickerSelect';
import { JOB_ROLE_SELECT_OPTIONS } from 'constants/staffing';

//actions
import {
  changeSelectedEndDate,
  changeSelectedStartDate,
  setContractorFilterPayload,
} from 'redux/actions/staffingActions';
import { contractorFiltersDerivedSelectOptions } from 'redux/selectors/staffing/getContractor';

import { STAFFING_TYPE_SELECT_OPTIONS } from 'constants/staffing';
import StaffingDatePicker from '../StaffingDatePicker/StaffingDatePicker';
import { selectSchedules } from 'redux/selectors/staffing/getSchedules';
import SearchableSelectContractorFilter from '../Contractors/ContractorFilter/SearchableSelectContractorFilter';

const FILTER_MIN_WIDTH = '150px';

// useStyles
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: '16px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  dateItem: {
    minWidth: FILTER_MIN_WIDTH,
    maxWidth: 'calc(50% - 8px)',
    '@media (min-width: 580px)': {
      maxWidth: FILTER_MIN_WIDTH,
    },
    alignSelf: 'end',
  },
  filterGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    columnGap: '16px',
    rowGap: '0',
    justifyContent: 'flex-start',
  },
  filterItem: {
    flexGrow: 1,
    flexBasis: 'calc(15% - 8px)',
    minWidth: FILTER_MIN_WIDTH,
    maxWidth: 'calc(50% - 8px)',
    '@media (min-width: 580px)': {
      maxWidth: FILTER_MIN_WIDTH,
    },
    alignSelf: 'end',
  },
}));

const SchedulesFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getSchedules = selectSchedules();
  const getFilters = contractorFiltersDerivedSelectOptions();
  const { cities, countries, timezones } = useSelector(state => getFilters(state));
  const { selectedScheduleStartDate, selectedScheduleEndDate } = useSelector(state =>
    getSchedules(state),
  );

  const handleSelect = (value, filterParam) => {
    dispatch(
      setContractorFilterPayload({
        [filterParam]: value,
      }),
    );
  };

  const handleStartDateChange = startDate => {
    dispatch(changeSelectedStartDate(startDate));
  };

  const handleEndDateChange = endDate => {
    dispatch(changeSelectedEndDate(endDate));
  };

  return (
    <div className={classes.main}>
      <div className={classes.dateGrid}>
        <div className={classes.dateItem}>
          <StaffingDatePicker
            label="Start Date"
            value={selectedScheduleStartDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className={classes.dateItem}>
          <StaffingDatePicker
            label="End Date"
            readOnly={false}
            value={selectedScheduleEndDate}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <div className={classes.filterGrid}>
        <div className={classes.filterItem}>
          <PickerSelect
            label="Staff Type"
            options={STAFFING_TYPE_SELECT_OPTIONS}
            onChange={value => handleSelect(value, 'staffingTypeFilter')}
          />
        </div>

        <div className={classes.filterItem}>
          <PickerSelect
            label="Role"
            options={JOB_ROLE_SELECT_OPTIONS}
            onChange={value => handleSelect(value, 'roleFilter')}
          />
        </div>
        <div className={classes.filterItem}>
          <SearchableSelectContractorFilter
            options={cities}
            label="City"
            onChange={(event, option) => {
              handleSelect(option, 'cityFilter');
            }}
          />
        </div>
        <div className={classes.filterItem}>
          <SearchableSelectContractorFilter
            options={countries}
            label="State/Country"
            onChange={(event, option) => {
              handleSelect(option, 'countryFilter');
            }}
          />
        </div>
        <div className={classes.filterItem}>
          <SearchableSelectContractorFilter
            options={timezones}
            label="Time Zone"
            onChange={(event, option) => {
              handleSelect(option, 'timeZoneFilter');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SchedulesFilter;
