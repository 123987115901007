import { createSelector } from 'reselect';
import { isLoaded } from 'react-redux-firebase';
import { map, compact, upperCase } from 'lodash';
import moment from 'moment';

import { getContractorJobs, filterOutContractors } from './getContractor';
import { PROJECT_SERVICES } from 'constants/staffing';

const projectsSelector = (state, id) => state.firestore.data.projects;
const contractorsSelector = (state, id) => state.firestore.data.contractors;
const projectMethodologiesSelector = state => state.firestore.data.projectMethodologies;
const staffBlockedTimesSelector = state => state.firestore.data.staffBlockedTimes;
const staffingSelector = state => state.staffing;

/**
 * Selector for retrieving Schedules
 */
export const selectSchedules = () => {
  return createSelector(
    [
      projectsSelector,
      contractorsSelector,
      staffingSelector,
      projectMethodologiesSelector,
      staffBlockedTimesSelector,
    ],
    (
      projects,
      contractors,
      staffing,
      projectMethodologies,
      staffBlockedTimes,
    ) => {
      if (!isLoaded(projects) || !isLoaded(contractors) || !isLoaded(staffBlockedTimes))
        return { isLoaded: false };

      const {
        //selectedScheduleMonth = new Date().getMonth(), //Defaults the Selected Month to the Current Month
        selectedScheduleStartDate = moment(), //Defaults the Selected Start Date to today
        selectedScheduleEndDate,
      } = staffing;

      const schedules = [];

      console.log('staffBlockedTimes', staffBlockedTimes);

      const userBlockedTimes = new Map();
      map(staffBlockedTimes, blockedTime => {
        const userId = blockedTime.userId;
        if (userId) {
          if (!userBlockedTimes.has(userId)) {
            userBlockedTimes.set(userId, []);
          }
          userBlockedTimes.get(userId).push(blockedTime);
        }
      });
      console.log('userBlockedTimes', userBlockedTimes);

      const filteredContractors = map(contractors, contractor => {
        return filterOutContractors(contractor, staffing);
      });

      const getDateRange = (start, end) => {
        const dates = [];

        if (!end) {
          dates.push(start);
        } else {
          let checkDate = moment(start);
          while (checkDate.isSameOrBefore(end, 'day')) {
            dates.push(moment(checkDate));
            checkDate.add(1, 'day');
          }
        }
        return dates;
      };
      const selectedDateRange = getDateRange(selectedScheduleStartDate, selectedScheduleEndDate);
      console.log('date range', selectedDateRange);

      const addSchedule = (schedules, contractor, job, startDate, endDate) => {
        const ensureDate = date => (date instanceof Date ? date : new Date(date));

        const jobDetails = `${[job.projectCompanyName, job.eventName, job.jobName]
          .filter(Boolean)
          .join(
            ' : ',
          )} : ${moment(startDate).format('M/D hA')} - ${moment(endDate).format('M/D hA')}`;

        schedules.push([
          `${contractor.displayName}  ${contractor.staffType ? `(${upperCase(contractor.staffType[0])})` : ''}`,
          jobDetails,
          ensureDate(startDate),
          ensureDate(endDate),
        ]);

        addedJobs.add(job.jobId); // Mark this job as added
      };

      const addedJobs = new Set(); // Add this line outside the loop

      //Map through All Contractors, and get the Jobs for each contractor
      map(compact(filteredContractors), contractor => {
        const contractorBlockedTimes = userBlockedTimes.get(contractor.userId) || [];
        map(contractorBlockedTimes, blockedTime => {
          const startDateTime = new Date(`${blockedTime.startDate}T${blockedTime.startTime}`);
          const endDateTime = new Date(`${blockedTime.endDate}T${blockedTime.endTime}`);
          addSchedule(
            schedules,
            contractor,
            {
              jobName: 'Blocked',
              projectStartTime: blockedTime.startTime,
              projectEndTime: blockedTime.endTime,
            },
            startDateTime,
            endDateTime,
          );
        });

        const contractorJobs = getContractorJobs(
          projects,
          contractor,
          projectMethodologies,
        );
        map(contractorJobs, job => {
          const jobEndDate = job.projectEndDate || job.projectStartDate;
          const jobStartDate = job.projectStartDate;
          const jobEndDateTime = `${jobEndDate}T${job.projectEndTime ? job.projectEndTime : job.projectStartTime}`;
          const jobStartDateTime = `${jobStartDate}T${job.projectStartTime}`;

          const addWeek = date => {
            return new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
          };

          selectedDateRange.forEach(rangeDate => {
            const selectDate = rangeDate.format('YYYY-MM-DD');
            try {
              if (
                !addedJobs.has(job.jobId) && // Check if the job hasn't been added yet
                ['accepted', 'signed', 'paid'].includes(job.status) &&
                selectDate >= jobStartDate &&
                selectDate <= jobEndDate
              ) {
                if (job.projectEventRecurrenceType === 'WEEKLY') {
                  // For weekly recurring events, create entries for each week
                  let currentStartDate = new Date(jobStartDateTime);
                  const endDate = new Date(jobEndDateTime);
                  let weekCount = 0;
                  while (
                    currentStartDate <= endDate &&
                    currentStartDate >= selectedDateRange[0] &&
                    currentStartDate <= selectedDateRange[selectedDateRange.length - 1]
                  ) {
                    weekCount++;

                    // Check if we should add this week based on methodology and service
                    const shouldAddWeek = !(
                      ['Facilitation', 'Master Facilitation'].includes(job.projectMethodology) &&
                      job.projectServiceType === PROJECT_SERVICES.Certification &&
                      [5, 6, 7, 8].includes(weekCount)
                    );
                    console.log(
                      `Should add week ${weekCount}: ${shouldAddWeek}. Job with ${job.projectMethodology} & ${job.projectServiceType}`,
                    );

                    if (shouldAddWeek) {
                      const currentEndDate = new Date(currentStartDate);
                      currentEndDate.setHours(new Date(jobEndDateTime).getHours());
                      currentEndDate.setMinutes(new Date(jobEndDateTime).getMinutes());

                      addSchedule(schedules, contractor, job, currentStartDate, currentEndDate);
                    }
                    currentStartDate = addWeek(currentStartDate);
                  }
                } else {
                  // For non-weekly events, add a single entry
                  addSchedule(schedules, contractor, job, jobStartDateTime, jobEndDateTime);
                }
              }
            } catch (error) {
              console.error(`Error processing job: ${job.eventName}`, {
                error: error.message,
                selectedDate: selectDate,
                jobStartDate: jobStartDate,
                jobEndDate: jobEndDate,
              });
            }
          });
        });
      });

      console.log('Building schedules for chart: ', schedules);

      return {
        schedules,
        selectedScheduleStartDate,
        selectedScheduleEndDate,
        selectedDateRange,
        isLoaded: true,
      };
    },
  );
};
