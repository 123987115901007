import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PickerSelect from 'components/Staffing/PickerSelect/PickerSelect';
import SearchableSelectContractorFilter from './SearchableSelectContractorFilter';

// constants
import { JOB_ROLE_SELECT_OPTIONS } from 'constants/staffing';
import { STAFFING_TYPE_SELECT_OPTIONS } from 'constants/staffing';
import { SENIORITY_SELECT_OPTIONS } from 'constants/staffing';

//actions
import { setContractorFilterPayload } from 'redux/actions/staffingActions';
import { contractorFiltersDerivedSelectOptions } from 'redux/selectors/staffing/getContractor';
import { selectCompanies } from 'redux/selectors/staffing/getDeals';
import { selectProjectMethodologies } from 'redux/selectors/staffing/getProject';
import StaffingDatePicker from 'components/Staffing/StaffingDatePicker/StaffingDatePicker';

// styles
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    columnGap: '16px',
    justifyContent: 'flex-start',
  },
  filterItem: {
    flexGrow: 1,
    flexBasis: 'calc(15% - 8px)',
    minWidth: '150px',
    maxWidth: 'calc(50% - 8px)',
    '@media (min-width: 580px)': {
      maxWidth: '150px',
    },
    alignSelf: 'end',
  },
}));

const ContractorFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Add state for the date
  const [selectedDate, setSelectedDate] = useState(null);

  const getFilters = contractorFiltersDerivedSelectOptions();
  const { cities, states, countries, languages, tags, timezones } = useSelector(state =>
    getFilters(state),
  );

  const getAllCompanies = selectCompanies();
  const { companies } = useSelector(state => getAllCompanies(state));

  const getAllProjectMethodologies = selectProjectMethodologies();
  const { projectMethodologies } = useSelector(state => getAllProjectMethodologies(state));

  const handleSelect = (value, filterParam) => {
    dispatch(
      setContractorFilterPayload({
        [filterParam]: value,
      }),
    );
  };

  return (
    <div className={cx(classes.main)}>
      <div className={classes.filterItem}>
        <PickerSelect
          classNames="flex-grow-1"
          label="Staff Type"
          options={STAFFING_TYPE_SELECT_OPTIONS}
          onChange={value => {
            handleSelect(value, 'staffingTypeFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <PickerSelect
          label="Primary Role"
          options={JOB_ROLE_SELECT_OPTIONS}
          onChange={value => {
            handleSelect(value, 'roleFilter');
          }}
        />
      </div>

      <div className={cx(classes.filterItem)}>
        <StaffingDatePicker
          label="Available On"
          readOnly={false}
          value={selectedDate}
          onChange={date => {
            setSelectedDate(date);
            handleSelect(date, 'availableOnFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <SearchableSelectContractorFilter
          options={cities}
          label="City"
          onChange={(event, option) => {
            handleSelect(option, 'cityFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <SearchableSelectContractorFilter
          options={states}
          label="State"
          onChange={(event, option) => {
            handleSelect(option, 'stateFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <SearchableSelectContractorFilter
          options={countries}
          label="Country"
          onChange={(event, option) => {
            handleSelect(option, 'countryFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <SearchableSelectContractorFilter
          options={timezones}
          label="Time Zone"
          onChange={(event, option) => {
            handleSelect(option, 'timeZoneFilter');
          }}
        />
      </div>

      {/* <div className={classes.filterItem}>
        <StaffingInputFilter
          onChange={event => {
            handleSelect(event.currentTarget.value, 'maxRateFilter');
          }}
          label="Max Rate"
          name="maxRateInput"
          type="number"
          inputProps={{
            min: 0,
          }}
        />
      </div> */}

      <div className={classes.filterItem}>
        <PickerSelect
          classNames="flex-grow-1"
          label="Seniority"
          options={SENIORITY_SELECT_OPTIONS}
          onChange={value => {
            handleSelect(value, 'seniorityFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <SearchableSelectContractorFilter
          options={companies}
          label="Has worked with"
          onChange={(event, option) => {
            handleSelect(option, 'companiesWorkedWithFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <PickerSelect
          classNames="flex-grow-1"
          label="Has worked on"
          options={projectMethodologies}
          onChange={value => {
            handleSelect(value, 'methodologiesWorkedWithFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <SearchableSelectContractorFilter
          options={tags}
          label="Tags"
          onChange={(event, option) => {
            handleSelect(option, 'tagsFilter');
          }}
        />
      </div>

      <div className={classes.filterItem}>
        <SearchableSelectContractorFilter
          options={languages}
          label="Languages"
          onChange={(event, option) => {
            handleSelect(option, 'languagesFilter');
          }}
        />
      </div>
    </div>
  );
};

export default ContractorFilter;
