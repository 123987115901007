// Note for Douglas -  /admin/free-sub-invite-u3a7oiMNxh95

import LoginPage from './views/Login';
import SignUpPage from './views/SignUp';
import ErrorPage from 'views/Pages/ErrorPage.js';
import Page404 from 'views/Pages/404.js';
import ConnectionError from 'views/Pages/ConnectionError.js';
import UserError from 'views/Pages/UserError.js';
import WhitelistInvite from './views/Admin/WhitelistInvite/WhitelistInvite';
import ProjectRedirect from './views/Admin/Staffing/ProjectRedirect';

// facilitator session views
import SessionFeedbackDetail from 'views/Admin/SessionFeedbackDetail/SessionFeedbackDetail';
import SessionDetail from 'views/Admin/SessionDetail/SessionDetail';
import Sessions from 'views/Admin/Sessions/Sessions';
import Admin2510Detail from 'views/Admin/25:10/Admin2510Detail';
import BreakoutsDetailAdmin from './views/Admin/Breakouts/BreakoutsDetailAdmin';
import AdminBrainwriting from './views/Admin/Brainwriting/AdminBrainwriting';
import NoteAndVoteAdmin from './views/Admin/NoteAndVote/NoteAndVoteAdmin';
import PollingAdmin from './views/Admin/Polling/PollingAdmin';
import Welcome from './views/Admin/Welcome/Welcome';
import NoteAndCategorizeAdmin from './views/Admin/NoteAndCategorize/NoteAndCategorizeAdmin';
import NoteAndMapAdmin from 'views/Admin/NoteAndMap/NoteAndMapAdmin';
import HeatMappingAdmin from './views/Admin/HeatMapping/HeatMappingAdmin';
import HowMightWeAdmin from 'views/Admin/HowMightWe/HowMightWeAdmin';
import UserJourneyMomentsAdmin from './views/Admin/UserJourneyMoments/UserJourneyMomentsAdmin';
import StoryFlowAdmin from './views/Admin/StoryFlow/StoryFlowAdmin';
import ScorecardAdmin from 'views/Admin/Scorecard/ScorecardAdmin';
import LightningDemoAdmin from './views/Admin/LightningDemo/LightningDemoAdmin';
import LiveScribingAdmin from './views/Admin/LiveScribing/LiveScribingAdmin';
import CardSortingAdmin from './views/Admin/CardSorting/CardSortingAdmin';
import NarrowingAdmin from './views/Admin/Narrowing/NarrowingAdmin';
import GiveawayAdmin from './views/Admin/Giveaway/GiveawayAdmin';
import SurveyAdmin from './views/Admin/Survey/SurveyAdmin';
import MuralAdmin from './views/Admin/Mural/MuralAdmin';
import AssessmentAdmin from 'views/Admin/Assessment/AssessmentAdmin';

//staffing views
import Staffing from './views/Admin/Staffing/Staffing';
import ContractorDashboard from './views/Admin/Staffing/ContractorDashboard';

// participant session views
import Feedback from 'views/Participant/Feedback/Feedback';
import Slides from 'views/Participant/Slides/Slides';
import Participant2510 from 'views/Participant/25:10/Participant2510';
import ParticipantBrainwriting from 'views/Participant/Brainwriting/ParticipantBrainwriting';
import NoteAndVoteParticipant from 'views/Participant/NoteAndVote/NoteAndVoteParticipant';
import PollingParticipant from 'views/Participant/Polling/PollingParticipant';
import BreakoutsDetailParticipant from './views/Participant/Breakouts/BreakoutsDetailParticipant';
import ParticipantWelcome from './views/Participant/Welcome/ParticipantWelcome';
import NoteAndCategorizeParticipant from 'views/Participant/NoteAndCategorize/NoteAndCategorizeParticipant';
import NoteAndMapParticipant from 'views/Participant/NoteAndMap/NoteAndMapParticipant';
import HeatMappingParticipant from './views/Participant/HeatMapping/HeatMappingParticipant';
import HowMightWeParticipant from 'views/Participant/HowMightWe/HowMightWeParticipant';
import UserJourneyMomentsParticipant from './views/Participant/UserJourneyMoments/UserJourneyMomentsParticipant';
import StoryFlowParticipant from './views/Participant/StoryFlow/StoryFlowParticipant';
import ScorecardParticipant from 'views/Participant/Scorecard/ScorecardParticipant';
import LightningDemoParticipant from './views/Participant/LightningDemo/LightningDemoParticipant';
import LiveScribingParticipant from './views/Participant/LiveScribing/LiveScribingParticipant';
import CardSortingParticipant from 'views/Participant/CardSorting/CardSortingParticipant';
import NarrowingParticipant from 'views/Participant/Narrowing/NarrowingParticipant';
import GiveawayParticipant from 'views/Participant/Giveaway/GiveawayParticipant';
import SurveyParticipant from 'views/Participant/Survey/SurveyParticipant';
import MuralParticipant from 'views/Participant/Mural/MuralParticipant';
import AssessmentParticipant from 'views/Participant/Assessment/AssessmentParticipant';

// @material-ui/icons
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import HistoryIcon from '@material-ui/icons/History';
import WorkIcon from '@material-ui/icons/Work';
import ProjectIcon from '@material-ui/icons/Assignment';
import ScheduleIcon from '@material-ui/icons/AssignmentInd';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

var dashRoutes = [
  // {
  //   path: "/sessions",
  //   name: "Upcoming Meetings",
  //   icon: PeopleIcon,
  //   component: Sessions,
  //   layout: "/admin",
  // },
  // {
  //   path: "/past-sessions",
  //   name: "Past Meetings",
  //   icon: HistoryIcon,
  //   component: Sessions,
  //   layout: "/admin",
  // },
  {
    path: '/past-sessions/:sessionId',
    name: 'Shared Meeting',
    component: SessionDetail,
    layout: '/admin',
    hideInSidebar: true,
  },
  // {
  //   path: "/shared-sessions",
  //   name: "Shared Meetings",
  //   icon: SupervisedUserCircleIcon,
  //   component: Sessions,
  //   layout: "/admin",
  // },
  // {
  //   path: "/staffing/",
  //   name: "Staffing",
  //   icon: WorkIcon,
  //   component: Staffing,
  //   layout: "/admin",
  //   staffingAdminRoute: true,
  // },

  {
    path: '/deals',
    name: 'Deals',
    icon: WorkIcon,
    component: Staffing,
    layout: '/admin/staffing',
    staffingAdminRoute: true,
  },
  {
    path: '/projects',
    name: 'Projects',
    icon: ProjectIcon,
    component: Staffing,
    layout: '/admin/staffing',
    staffingAdminRoute: true,
  },
  {
    path: '/contractors',
    name: 'Staff',
    icon: PeopleIcon,
    component: Staffing,
    layout: '/admin/staffing',
    staffingAdminRoute: true,
  },
  {
    path: '/schedules',
    name: 'Schedules',
    icon: ScheduleIcon,
    component: Staffing,
    layout: '/admin/staffing',
    staffingAdminRoute: true,
  },
  {
    path: '/project-redirect/:projectId',
    name: 'Project Redirect',
    component: ProjectRedirect,
    layout: '/admin',
    hideInSidebar: true,
  },
  {
    path: '/contractor/',
    name: 'My Jobs',
    icon: WorkIcon,
    component: ContractorDashboard,
    layout: '/admin',
    vCContractorRoute: true,
    isNewSection: true,
  },
  {
    path: '/contractor-info/',
    name: 'My Info',
    icon: AccountBoxIcon,
    component: ContractorDashboard,
    layout: '/admin',
    vCContractorRoute: true,
  },
  {
    path: '/contractor-availability/',
    name: 'My Availability',
    icon: CalendarTodayIcon,
    component: ContractorDashboard,
    layout: '/admin',
    vCContractorRoute: true,
  },
  {
    path: '/sessions/:sessionId',
    name: 'Meeting',
    component: SessionDetail,
    layout: '/admin',
    hideInSidebar: true,
    // sessionRoute: true
  },
  {
    path: '/shared-sessions/:sessionId',
    name: 'Shared Meeting',
    component: SessionDetail,
    layout: '/admin',
    hideInSidebar: true,
    // sessionRoute: true
  },
  {
    path: '/sessions/:sessionId/feedback',
    name: 'Feedback',
    component: SessionFeedbackDetail,
    layout: '/admin',
    hideInSidebar: true,
    sessionRoute: true,
    activeRouteName: 'feedback',
  },
  {
    path: '/shared-sessions/:sessionId/feedback',
    name: 'Shared Feedback',
    component: SessionFeedbackDetail,
    layout: '/admin',
    hideInSidebar: true,
    sessionRoute: true,
    activeRouteName: 'feedback',
  },
  {
    path: '/sessions/:sessionId/25:10/:activityId',
    name: '25:10',
    component: Admin2510Detail,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: '25:10',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/25:10/:activityId',
    name: 'Shared 25:10',
    component: Admin2510Detail,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: '25:10',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/brainwriting/:activityId',
    name: 'Brain Writing',
    component: AdminBrainwriting,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'brainwriting',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/brainwriting/:activityId',
    name: 'Shared Brain Writing',
    component: AdminBrainwriting,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'brainwriting',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/noteAndVote/:activityId',
    name: 'Note & Vote',
    component: NoteAndVoteAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'noteAndVote',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/noteAndVote/:activityId',
    name: 'Shared Note & Vote',
    component: NoteAndVoteAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'noteAndVote',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/noteAndCategorize/:activityId',
    name: 'Note & Categorize',
    component: NoteAndCategorizeAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'noteAndCategorize',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/heatMapping/:activityId',
    name: 'Heat-Mapping',
    component: HeatMappingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'heatMapping',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/heatMapping/:activityId',
    name: 'Heat-Mapping',
    component: HeatMappingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'heatMapping',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/howMightWe/:activityId',
    name: 'HMW',
    component: HowMightWeAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'howMightWe',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/howMightWe/:activityId',
    name: 'HMW',
    component: HowMightWeAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'howMightWe',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/userJourneyMoments/:activityId',
    name: 'User Journey Moments',
    component: UserJourneyMomentsAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'userJourneyMoments',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/userJourneyMoments/:activityId',
    name: 'User Journey Moments',
    component: UserJourneyMomentsAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'userJourneyMoments',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/scorecard/:activityId',
    name: 'HMW',
    component: ScorecardAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'scorecard',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/scorecard/:activityId',
    name: 'HMW',
    component: ScorecardAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'scorecard',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/lightningDemo/:activityId',
    name: 'Lightning Demo',
    component: LightningDemoAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'lightningDemo',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/lightningDemo/:activityId',
    name: 'Lightning Demo',
    component: LightningDemoAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'lightningDemo',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/liveScribing/:activityId',
    name: 'Live Scribing',
    component: LiveScribingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'liveScribing',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/liveScribing/:activityId',
    name: 'Live Scribing',
    component: LiveScribingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'liveScribing',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/polling/:activityId',
    name: 'Polling',
    component: PollingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'polling',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/polling/:activityId',
    name: 'Shared Polling',
    component: PollingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'polling',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/breakouts/:breakoutId',
    name: 'Breakouts',
    component: BreakoutsDetailAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'breakouts',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/breakouts/:breakoutId',
    name: 'Shared Breakouts',
    component: BreakoutsDetailAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'breakouts',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/slides',
    name: 'Slides',
    component: Slides,
    layout: '/admin',
    hideInSidebar: true,
    sessionRoute: true,
    activeRouteName: 'slides',
  },
  {
    path: '/shared-sessions/:sessionId/slides',
    name: 'Shared Slides',
    component: Slides,
    layout: '/admin',
    hideInSidebar: true,
    sessionRoute: true,
    activeRouteName: 'slides',
  },
  {
    path: '/sessions/:sessionId/welcome',
    name: 'Welcome',
    component: Welcome,
    layout: '/admin',
    hideInSidebar: true,
    sessionRoute: true,
    activeRouteName: 'welcome',
  },
  {
    path: '/shared-sessions/:sessionId/welcome',
    name: 'Shared Welcome',
    component: Welcome,
    layout: '/admin',
    hideInSidebar: true,
    sessionRoute: true,
    activeRouteName: 'welcome',
  },
  {
    path: '/invite-u3a7oiMNxh95',
    name: 'Invite',
    component: WhitelistInvite,
    layout: '/admin',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/storyFlow/:activityId',
    name: 'Story Flow',
    component: StoryFlowAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'storyFlow',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/storyFlow/:activityId',
    name: 'Story Flow',
    component: StoryFlowAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'storyFlow',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/noteAndMap/:activityId',
    name: 'Note & Map',
    component: NoteAndMapAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'noteAndMap',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/noteAndMap/:activityId',
    name: 'Shared Note & Map',
    component: NoteAndMapAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'noteAndMap',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/cardSorting/:activityId',
    name: 'Card Sorting',
    component: CardSortingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'cardSorting',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/cardSorting/:activityId',
    name: 'Shared Card Sorting',
    component: CardSortingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'cardSorting',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/giveaway/:activityId',
    name: 'Giveaway',
    component: GiveawayAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'giveaway',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/giveaway/:activityId',
    name: 'Shared Giveaway',
    component: GiveawayAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'giveaway',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/survey/:activityId',
    name: 'Assessment V1',
    component: SurveyAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'survey',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/assessment/:activityId',
    name: 'Assessment V2',
    component: AssessmentAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'assessment',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/survey/:activityId',
    name: 'Shared Assessment V1',
    component: SurveyAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'survey',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/assessment/:activityId',
    name: 'Shared Assessment V2',
    component: AssessmentAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'assessment',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/narrowing/:activityId',
    name: 'Narrowing',
    component: NarrowingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'narrowing',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/narrowing/:activityId',
    name: 'Shared Narrowing',
    component: NarrowingAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'narrowing',
    hideInSidebar: true,
  },
  {
    path: '/sessions/:sessionId/mural/:activityId',
    name: 'Mural',
    component: MuralAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'mural',
    hideInSidebar: true,
  },
  {
    path: '/shared-sessions/:sessionId/mural/:activityId',
    name: 'Shared Mural',
    component: MuralAdmin,
    layout: '/admin',
    sessionRoute: true,
    activeRouteName: 'mural',
    hideInSidebar: true,
  },
];

export const sessionRoutes = [
  {
    path: '/:sessionId/welcome',
    name: 'Welcome',
    component: ParticipantWelcome,
    layout: '/session',
    activeRouteName: 'welcome',
  },
  {
    path: '/:sessionId/feedback',
    name: 'Feedback',
    component: Feedback,
    layout: '/session',
    activeRouteName: 'feedback',
  },
  {
    path: '/:sessionId/participant/feedback',
    name: 'Feedback',
    component: Feedback,
    layout: '/session',
    activeRouteName: 'feedback',
  },
  {
    path: '/:sessionId/slides/:slidesSessionId',
    name: 'Slides',
    component: Slides,
    layout: '/session',
    activeRouteName: 'slides',
  },
  {
    path: '/:sessionId/25:10/:activityId',
    name: '25:10',
    component: Participant2510,
    layout: '/session',
    activeRouteName: '25:10',
  },
  {
    path: '/:sessionId/brainwriting/:activityId',
    name: 'Brain Writing',
    component: ParticipantBrainwriting,
    layout: '/session',
    activeRouteName: 'brainwriting',
  },
  {
    path: '/:sessionId/noteAndVote/:activityId',
    name: 'Note & Vote',
    component: NoteAndVoteParticipant,
    layout: '/session',
    activeRouteName: 'noteAndVote',
  },
  {
    path: '/:sessionId/noteAndCategorize/:activityId',
    name: 'Note & Categorize',
    component: NoteAndCategorizeParticipant,
    layout: '/session',
    activeRouteName: 'noteAndCategorize',
  },
  {
    path: '/:sessionId/noteAndMap/:activityId',
    name: 'Note & Map',
    component: NoteAndMapParticipant,
    layout: '/session',
    activeRouteName: 'noteAndMap',
  },
  {
    path: '/:sessionId/userJourneyMoments/:activityId',
    name: 'User Journey Moments',
    component: UserJourneyMomentsParticipant,
    layout: '/session',
    activeRouteName: 'userJourneyMoments',
  },
  {
    path: '/:sessionId/heatMapping/:activityId',
    name: 'Heat-Mapping',
    component: HeatMappingParticipant,
    layout: '/session',
    activeRouteName: 'heatMapping',
  },
  {
    path: '/:sessionId/howMightWe/:activityId',
    name: 'HMW',
    component: HowMightWeParticipant,
    layout: '/session',
    activeRouteName: 'howMightWe',
  },
  {
    path: '/:sessionId/scorecard/:activityId',
    name: 'HMW',
    component: ScorecardParticipant,
    layout: '/session',
    activeRouteName: 'scorecard',
  },
  {
    path: '/:sessionId/polling/:activityId',
    name: 'Polling',
    component: PollingParticipant,
    layout: '/session',
    activeRouteName: 'polling',
  },
  {
    path: '/:sessionId/breakouts/:breakoutId',
    name: 'Breakouts',
    component: BreakoutsDetailParticipant,
    layout: '/session',
    activeRouteName: 'breakouts',
  },
  {
    path: '/:sessionId/storyFlow/:activityId',
    name: 'Story Flow',
    component: StoryFlowParticipant,
    layout: '/session',
    activeRouteName: 'storyFlow',
  },
  {
    path: '/:sessionId/lightningDemo/:activityId',
    name: 'Lightning Demo',
    component: LightningDemoParticipant,
    layout: '/session',
    activeRouteName: 'lightningDemo',
  },
  {
    path: '/:sessionId/liveScribing/:activityId',
    name: 'LiveScribing',
    component: LiveScribingParticipant,
    layout: '/session',
    activeRouteName: 'liveScribing',
  },
  {
    path: '/:sessionId/cardSorting/:activityId',
    name: 'CardSorting',
    component: CardSortingParticipant,
    layout: '/session',
    activeRouteName: 'cardSorting',
  },
  {
    path: '/:sessionId/giveaway/:activityId',
    name: 'Giveaway',
    component: GiveawayParticipant,
    layout: '/session',
    activeRouteName: 'giveaway',
  },
  {
    path: '/:sessionId/survey/:activityId',
    name: 'Assessment V1',
    component: SurveyParticipant,
    layout: '/session',
    activeRouteName: 'survey',
  },
  {
    path: '/:sessionId/assessment/:activityId',
    name: 'Assessment V2',
    component: AssessmentParticipant,
    layout: '/session',
    activeRouteName: 'assessment',
  },
  {
    path: '/:sessionId/narrowing/:activityId',
    name: 'Narrowing',
    component: NarrowingParticipant,
    layout: '/session',
    activeRouteName: 'narrowing',
  },
  {
    path: '/:sessionId/mural/:activityId',
    name: 'Mural',
    component: MuralParticipant,
    layout: '/session',
    activeRouteName: 'mural',
  },
];

export const pageRoutes = [
  // {
  //   path: "/signup",
  //   name: "Sign Up",
  //   component: SignUpPage,
  //   layout: "/auth",
  // },
  {
    path: '/login-page',
    name: 'Login Page',
    component: LoginPage,
    layout: '/auth',
  },
  {
    path: '/404',
    name: 'Not Found',
    component: Page404,
    layout: '/auth',
  },
  {
    path: '/connection-error',
    name: "Can't Connect",
    component: ConnectionError,
    layout: '/auth',
  },
  {
    path: '/user-error',
    name: 'User Error',
    component: UserError,
    layout: '/auth',
  },
];

export default dashRoutes;
